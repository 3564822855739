import * as Yup from 'yup';
import * as yupUtils from 'hellospa/common/utils/yup';
import { defineMessages } from 'react-intl';
import { intl } from 'hellospa/common/hs-intl-provider';
import { Actions } from './index';
import {
  validateEmail,
  asciiRegex,
  asciiMessage,
} from '../utils/validate-email';

const messages = defineMessages({
  recipientName: {
    id: '',
    defaultMessage: 'Recipient name',
    description:
      'Label for input fields that gets injected into error messages',
  },
  anEmailAddress: {
    id: '',
    defaultMessage: 'An email address',
    description:
      'Label for input fields that gets injected into error messages',
  },
  CCs: {
    id: '',
    defaultMessage: 'CCs',
    description:
      'Label for input fields that gets injected into error messages',
  },
  ccRoles: {
    id: '',
    defaultMessage: 'CC roles',
    description:
      'Label for input fields that gets injected into error messages',
  },
  emailRequired: {
    id: '',
    defaultMessage: 'An email address is required',
    description:
      "Error message to be displayed when signer's email address is not provided",
  },
  validEmailRequired: {
    id: '',
    defaultMessage: 'A valid email address is required',
    description:
      "Error message to be displayed when signer's email address is not a valid email address",
  },
  nameMaxLimit: {
    id: '',
    defaultMessage: 'Name must be at most 128 characters',
    description:
      "Error message to be displayed when signer's name is longer than 128 characters",
  },
  emailMaxLimit: {
    id: '',
    defaultMessage: 'Email must be at most 100 characters',
    description:
      "Error message to be displayed when signer's email address is longer than 100 characters",
  },
});

export const ccTypes = {
  CCEmail: 'ccEmail',
  CCRole: 'ccRole',
} as const;
export type CCTypes = (typeof ccTypes)[keyof typeof ccTypes];

interface BaseCC {
  readonly id: string;
}

export interface CCEmail extends BaseCC {
  readonly type: 'ccEmail';
  readonly email: string;
  readonly role?: CCRole | null;
}

export interface CCRole extends BaseCC {
  readonly type: 'ccRole';
  readonly name: string;
}

export type CC = CCEmail | CCRole;

const ccRoleSchemaDefinition = {
  type: Yup.mixed().oneOf(['ccRole']),
  id: Yup.string().required(),
  name: Yup.string()
    .trim()
    .label(intl.formatMessage(messages.recipientName))
    .required()
    .max(128),
};

export const ccRoleSchema = Yup.object<CCRole>(ccRoleSchemaDefinition);

// CC Roles don't have emails while using templates, and this acts as a conditional validation.
export const testCCEmails = (value: string) =>
  !value ||
  Yup.string()
    .matches(asciiRegex, intl.formatMessage(asciiMessage.noAscci))
    .isValidSync(value);

export const ccEmailSchema = Yup.object<CCEmail>({
  type: Yup.mixed().oneOf(['ccEmail']),
  id: Yup.string().required(),
  email: Yup.string()
    .trim()
    .label(intl.formatMessage(messages.anEmailAddress))
    .test('ascii', intl.formatMessage(asciiMessage.noAscci), testCCEmails)
    .sequence([
      () => Yup.string().email(intl.formatMessage(messages.validEmailRequired)),
      () => Yup.string().max(100, intl.formatMessage(messages.emailMaxLimit)),
      (testContext: Yup.TestContext) =>
        Yup.string().test(
          'email',
          'Email Validation Error',
          async (value: string) => {
            return validateEmail(testContext, value);
          },
        ),
    ]),
  role: Yup.object().nullable().shape(ccRoleSchemaDefinition).default(null),
});

export const multiCCEmailSchema = Yup.object().shape({
  ccRecipients: Yup.array()
    .of(ccEmailSchema)
    .label(intl.formatMessage(messages.CCs))
    .unique(['email']),
});

export const meOnlyMultiCCEmailSchema = Yup.object().shape({
  ccRecipients: Yup.array()
    .of(
      ccEmailSchema.clone().shape({
        email: Yup.string()
          .trim()
          .label(intl.formatMessage(messages.anEmailAddress))
          .test('ascii', intl.formatMessage(asciiMessage.noAscci), testCCEmails)
          .sequence([
            () =>
              Yup.string().email(
                intl.formatMessage(messages.validEmailRequired),
              ),
            () =>
              Yup.string().required(intl.formatMessage(messages.emailRequired)),
            () =>
              Yup.string().max(100, intl.formatMessage(messages.emailMaxLimit)),
            (testContext: Yup.TestContext) =>
              Yup.string().test(
                'email',
                'Email Validation Error',
                async (value: string) => {
                  return validateEmail(testContext, value);
                },
              ),
          ]),
      }),
    )
    .label(intl.formatMessage(messages.CCs))
    .unique(['email']),
});

export const multiCCRoleSchema = Yup.object().shape({
  ccRecipients: Yup.array()
    .of(ccRoleSchema)
    .label(intl.formatMessage(messages.ccRoles))
    .unique(['name']),
});

const ccUnionSchema = {
  ccEmail: ccEmailSchema,
  ccRole: ccRoleSchema,
};
export const ccSchema = yupUtils.lazyUnion<
  yupUtils.InferUnion<typeof ccUnionSchema>,
  'type'
>('type', ccUnionSchema);

export interface CreateCCAction {
  type: Actions.CreateCC;
  payload: CC;
}

export interface SetCCsAction {
  type: Actions.SetCCs;
  payload: CC[];
}

export interface DeleteCCAction {
  type: Actions.DeleteCC;
  payload: CC['id'];
}

interface BaseUpdate<A extends Actions, T, TKey extends keyof T> {
  type: A;
  payload: {
    id: T[TKey];
    updates: Partial<T>;
  };
}

export type UpdateCCAction = BaseUpdate<Actions.UpdateCC, CC, 'id'>;

export type CCActions =
  | SetCCsAction
  | CreateCCAction
  | DeleteCCAction
  | UpdateCCAction;
