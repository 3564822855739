import signatureActions from 'signer-app/context/signer-app-client/signature';
import * as signer from 'signer-app/context/signer-app-client/signer';
import { logAppActionsInDevelopment } from 'signer-app/utils/log-app-actions';
import * as tours from 'signer-app/context/signer-app-client/tours';
import * as authentication from 'signer-app/context/signer-app-client/authentication';
import { mockSignerAppClient } from '@dropbox/dpc-signer-app';
import { Middleware, SignerAppApi } from '@dropbox/sign-internal-client';
import {
  getCSRFToken,
  needsCSRFToken,
} from 'js/sign-components/common/hs-fetch';

type SignerApi = ReturnType<typeof mockSignerAppClient>['signerApi'];

export type SignerAppClient = {
  signatures: ReturnType<typeof signatureActions>;
  authentication: typeof authentication;
  signer: typeof signer;
  signerApi: SignerApi;
  tours: typeof tours;
};

// There is a mock version of this used for tests.
/** istanbul ignore next */
export const buildSignerAppClient = (
  preloadedTsmGroupKey: string,
): SignerAppClient => {
  const mockClient = mockSignerAppClient();
  const signerApi = mockClient.signerApi;
  const hostname = new URL('/', window.location.href).host;
  const middleware: Middleware['pre'] = async (req) => {
    req.url = req.url.replace(
      'https://app.hellosign.com',
      `https://${hostname}`,
    );

    (req.init.headers as any).Accept = 'application/json';
    if (needsCSRFToken(req.url)) {
      // Adding global X-CSRF-Token
      (req.init.headers as any)['X-CSRF-Token'] = getCSRFToken();
    }

    // Add pre middleware
    return req;
  };
  const signerAppApi = new SignerAppApi().withPreMiddleware(middleware);
  // @ts-ignore
  signerApi.signerAppSignatureRequestGetRequest = async (...args) => {
    const response = await signerAppApi.signerAppSignatureRequestGetRequest(
      ...args,
    );
    return response;
  };
  // signerAppApi.signerAppSignatureRequestGetRequest.bind(signerApi);

  const actions = {
    signatures: signatureActions(preloadedTsmGroupKey),
    signer,
    tours,
    signerApi,
    authentication,
  };
  return logAppActionsInDevelopment(actions);
};
