import { ThunkAction, StoreShape } from 'hellospa/redux/types';
import * as selectors from '../selectors';
import {
  Actions,
  PrepAndSendAction,
} from 'hellospa/page/prep-and-send/data/types';
import { SendRequestResponse } from 'hello-react/web-app-client/namespace/prep-and-send';
import capture from './capture';
import { BulkSendData } from '../types/bulk-send';
import {
  setCsvEditorData,
  setCsvEditorUploadErrors,
} from '../csv-editor/csv-editor-slice';
import { CsvEditorRow } from '../types/csv-editor-row';
import { CsvEditorError } from '../types/csv-editor-error';
import { SHARING_INVITE_RATE_LIMIT } from 'common/constants/user-presentable-verdict';

export const initBulkSend =
  (): ThunkAction<Promise<null>> => async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const cacheKey = selectors.getTransmissionGroupGuid(getState());
    const templatesGuids = selectors.getTemplatesGuids(getState());
    if (templatesGuids && templatesGuids.length > 0) {
      const dataKey = await appActions.prepAndSend.bulkSendInitialize(
        cacheKey,
        templatesGuids,
      );
      dispatch({
        type: Actions.BulkSendInitialize,
        payload: dataKey,
      });
    }

    return null;
  };

export const getBulkSendExample =
  (): ThunkAction<Promise<void>> => async () => {};

export const validateBulkSendSigners =
  (file: File): ThunkAction<Promise<null | string>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const signerKey = selectors.getTransmissionGroupGuid(getState());
    const templates = selectors.getTemplates(getState());
    const templateIds = templates.map((template) => template.templateGuid);

    if (templates && templates.length > 0) {
      const error = await appActions.prepAndSend.validateBulkSendData(
        file,
        signerKey,
        templateIds,
      );
      if (error) {
        return error.error;
      }
    }
    return null;
  };

export const validateBulkSendSignersNew =
  (file: File): ThunkAction<Promise<null | string>> =>
  async (
    // eslint-disable-line max-len
    dispatch,
    getState,
    getExtra,
  ) => {
    const { appActions } = getExtra();
    const bulkSendDataKey = selectors.getBulkSendDataKey(getState());

    if (bulkSendDataKey) {
      const error = await appActions.prepAndSend.uploadSignerFileBulkSend(
        bulkSendDataKey,
        file,
      );

      if (error.errors.length !== 0) {
        dispatch(setCsvEditorUploadErrors(error.errors));
        return error.errors[0];
      }
      dispatch(
        setCsvEditorData({
          rows: error?.signers as unknown as CsvEditorRow[],
          rowHeaders: error?.header as string[],
          errors: error?.rowErrors as unknown as CsvEditorError[],
        }),
      );
      dispatch(setCsvEditorUploadErrors([]));
    }
    return null;
  };

export const saveSignerCSV = (file: File): PrepAndSendAction => {
  return {
    type: Actions.SetBulkSendData,
    payload: {
      signerFile: {
        name: file.name,
        type: 'signer',
        status: 'converting',
        progress: 0,
      },
    },
  };
};

export const markSignerCSVComplete = (file: File) => {
  const signerFile = {
    name: file.name,
    type: 'signer',
    status: 'ok',
    progress: 1,
  };
  return {
    type: Actions.SetBulkSendData,
    payload: {
      signerFile,
    },
  };
};

export const deleteSignerCSV = (): PrepAndSendAction => ({
  type: Actions.SetBulkSendData,
  payload: {
    signerFile: undefined,
  },
});

export const submitBulkSendRequest =
  (): ThunkAction<Promise<SendRequestResponse>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const signerKey = selectors.getTransmissionGroupGuid(getState());
    const document = selectors.getDocument(getState());
    const title = document.title;
    const message = document.message;
    const ccs = selectors.getCCs(getState());
    try {
      const redirectUrl = await appActions.prepAndSend.submitBulkSendRequest(
        signerKey,
        title,
        message,
        ccs,
      );
      return {
        success: true,
        redirectUrl,
      };
    } catch (error: any) {
      if (error.message === SHARING_INVITE_RATE_LIMIT) {
        return {
          success: false,
          status: 'error',
          errorMsg: error.message,
        };
      }
      return {
        errors: [error.message],
      };
    }
  };

export const validateSignerFile = async (
  state: StoreShape,
): Promise<boolean> => {
  const schema = selectors.getRecipientValidationSchema(state);
  const data = {
    signerFile: selectors.getBulkSendData(state).signerFile,
  };
  if (!schema) {
    return true;
  }

  const errors = await capture(schema, data);

  return errors.length === 0;
};

export const setBulkSendData = (
  payload: Partial<BulkSendData>,
): PrepAndSendAction => ({
  type: Actions.SetBulkSendData,
  payload,
});

export const setBulkSendDataNew = (payload: string): PrepAndSendAction => ({
  type: Actions.BulkSendInitialize,
  payload,
});

export const resetSignerFile = (): PrepAndSendAction => ({
  type: Actions.ResetSignerFile,
});
