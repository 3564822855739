import amplitude from 'amplitude-js';
import { PAPEvent } from '@dropbox/pap-events/base/event';
import { IntegrationSource } from '@dropbox/pap-events/enums/integration_source';
import { isProduction, snakeCaseObj } from 'js/sign-components/common/ts-utils';

type AmplitudeUserId = string | number | null | undefined;

const AMPLITUDE_KEY = isProduction()
  ? '233ffee62e411e84d1807b55c77e9087'
  : '36f19c68dee21f264e20d9db589114df';
const API_ENDPOINT = 'www.dropbox.com/amplitude_proxy/ingest_lenient';

export function initPAPClient() {
  amplitude.getInstance().init(AMPLITUDE_KEY, undefined, {
    apiEndpoint: API_ENDPOINT,
  });

  return amplitude;
}

export const logPAPEvent = (
  event: PAPEvent,
  userId: AmplitudeUserId = undefined,
  foreignUserId: AmplitudeUserId = undefined,
  userProperties: object = {},
) =>
  initPAPClient()
    .getInstance()
    .logEvent(`${event.action}.${event.object}`, {
      user_properties: userProperties,
      event_action: event.action,
      event_object: event.object,
      event_class: event.class,
      user_identifier: {
        user_id: userId,
        foreign_user_id: foreignUserId,
        // Dropbox is expecting a literal HELLOSIGN value for this API request
        // eslint-disable-next-line no-restricted-syntax
        foreign_user_provider: 'HELLOSIGN',
      },
      ...(event.properties ? snakeCaseObj(event.properties) : {}),
    });

export const integrationNames = {
  DeepIntegration: 'deepintegration',
  OfficeSelfSign: 'officeAddInSelfSign',
  SharePoint: 'SharePoint',
  HubSpot: 'hubspot',
  HubSpotV2: 'hubspotv2',
} as const;
export type IntegrationNames =
  (typeof integrationNames)[keyof typeof integrationNames];

export function retrieveFormattedIntegrationSource(
  integrationName: string,
): IntegrationSource {
  switch (integrationName) {
    case integrationNames.OfficeSelfSign:
      return 'office_add_in';
    case integrationNames.DeepIntegration:
      return 'deep_integration';
    case integrationNames.HubSpot:
      return 'hubspot';
    case integrationNames.HubSpotV2:
      return 'hubspotv2';
    case integrationNames.SharePoint:
      return 'sharepoint';
    default:
      return 'standalone';
  }
}
