import React from 'react';
import {
  SignAppClientProvider,
  useSignAppClient,
} from 'js/sign-components/sign-app-client/context';
import { SignerAppClient } from 'dpc-sign/build-signer-app-client';

export type { SignerAppClient };

// export const {
//   useContext: useSignerAppClient, Provider: SignerAppClientProvider,
// } = createSimpleDataContext<SignerAppClient>('signer-app-client');

export const useSignerAppClient = (): SignerAppClient => {
  return useSignAppClient();
};

type ProviderProps = React.PropsWithChildren<{
  client: SignerAppClient;
}>;
export const SignerAppClientProvider = ({
  client,
  ...props
}: ProviderProps) => (
  <SignAppClientProvider client={client as any} {...props} />
);
