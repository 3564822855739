import { defineMessages } from 'react-intl';
import { ValueOfArray } from 'signer-app/utils/value-of-array';
import { AutoFillType } from 'js/sign-components/generated/types/HelloRequest';

export const validationMessages = defineMessages({
  optionNumbersOnly: {
    id: '13e3add68c045eec976509d73e272b46016b87384773a7324ea1d62b9f9fb3aa',
    description:
      'A field validation option name requiring that the user enter only numbers.',
    defaultMessage: 'Numbers only',
  },
  optionLettersOnly: {
    id: 'a693908bc5ef20a76b888f916335b63f9310b0eb097bbd7f26489346fc11820f',
    description:
      'A field validation option name requiring that the user enter only letters.',
    defaultMessage: 'Letters only',
  },
  optionPhoneNumber: {
    id: '58e4cde585bd7b1e8a543b2c4a2978d5278b213f82f853f4ad3cc58c12b20aca',
    description:
      'A field validation option name requiring that the user enter only a valid phone number.',
    defaultMessage: '(USA) Phone number',
  },
  optionBankRoutingNumber: {
    id: '54d895b1176730aeb21c790e66f3d43429a77b4b056bbb3eb7f645c1a88e77bd',
    description:
      'A field validation option name requiring that the user enter only a valid bank routing number.',
    defaultMessage: '(USA) Bank routing number',
  },
  optionBankAccountNumber: {
    id: '4135845bfd2647b2635a97dcf747fae5884f5fe0d89f981a0e7046f2bfb1c26b',
    description:
      'A field validation option name requiring that the user enter only a valid bank account number.',
    defaultMessage: '(USA) Bank account number',
  },
  optionEmailAddress: {
    id: 'ca8e762f39963d7adf0aaae44b5aba0ae4c2b5fdf1229f9d7ec5b28dfe7fed83',
    description:
      'A field validation option name requiring that the user enter only a valid email address.',
    defaultMessage: 'Email address',
  },
  optionZipCode: {
    id: '37080f57cc699d9909c05d4fe6021344821049289f1f488f0ac5b97d25f1f1c7',
    description:
      'A field validation option name requiring that the user enter only a valid zip code.',
    defaultMessage: '(USA) Zip code',
  },
  optionSSN: {
    id: 'c51f1b92d7b462faa8de7c0d95109191ae420a5054e4279a690ad7751fc6b0ba',
    description:
      'A field validation option name requiring that the user enter only a valid social security number.',
    defaultMessage: '(USA) Social security number',
  },
  optionEIN: {
    id: '6210ca6e15bf5e2fb9f5cbf7b201bee1695bdbffa7e9a37efc1a116b4c111d54',
    description:
      'A field validation option name requiring that the user enter only a valid employer identification number.',
    defaultMessage: '(USA) Employer identification number',
  },
  optionCustomRegex: {
    id: '3e0c51d5a17cdc2b38dfc2a8c939ac983c3bdb9f512cf4adeb25c7fb7b875367',
    description:
      "A field validation option name which validates the user's input using a custom regular expression.",
    defaultMessage: 'Custom regex',
  },
  detailPhoneNumber: {
    id: 'f96e4c44b76c3582cd860452df7c873ffc049398ab39c0ea60b4dd68fcd27024',
    description:
      'The description of a field validation option which requires the to user enter a valid phone number.',
    defaultMessage: '10 digits will be required',
  },
  detailBankRoutingNumber: {
    id: 'a68748626bd64620780ab7b579c963b3eb00491b10faf519e950927021e22fa5',
    description:
      'The description of a field validation option which requires the to user enter a bank routing number.',
    defaultMessage: '9 digits will be required',
  },
  detailBankAccountNumber: {
    id: '1655e8ac18cd078b378847cae59ffb8e2caf0ae38c3415daf8482b24a2a02c8c',
    description:
      'The description of a field validation option which requires the to user enter a bank account number.',
    defaultMessage: 'Minimum of 6 digits will be required',
  },
  detailZipCode: {
    id: '669d10b1a6d6924a059950116d0f8b28367606af9df624fc06ce8c6891560d97',
    description:
      'The description of a field validation option which requires the to user enter a valid zip ode.',
    defaultMessage: '5 or 9 digits will be required',
  },
  detailSSN: {
    id: '2db37d357c70ecaff12a09425e1b02899634a4be54ce62f507584bcf20bad043',
    description:
      'The description of a field validation option which requires the to user enter a valid social security number.',
    defaultMessage: '9 digits will be required',
  },
  detailEIN: {
    id: 'c2404521edcdd55d12199179a0ac1b61c307ad11cecfc9bb6a840636c8d83546',
    description:
      'The description of a field validation option which requires the to user enter a valid employer identification number.',
    defaultMessage: '9 digits will be required',
  },
  detailCustomRegex: {
    id: 'a1c2d0430674f8e10bad75176a0935a0793429433839211c35d69fdbeaa548f5',
    description:
      "The description of a field validation option which validates the user's input using a custom regular expression.",
    defaultMessage: 'Specify a regular expression',
  },
});

export const validationMessageMap = {
  numbers_only: validationMessages.optionNumbersOnly,
  letters_only: validationMessages.optionLettersOnly,
  phone_number: validationMessages.optionPhoneNumber,
  bank_routing_number: validationMessages.optionBankRoutingNumber,
  bank_account_number: validationMessages.optionBankAccountNumber,
  email_address: validationMessages.optionEmailAddress,
  zip_code: validationMessages.optionZipCode,
  social_security_number: validationMessages.optionSSN,
  employer_identification_number: validationMessages.optionEIN,
  custom_regex: validationMessages.optionCustomRegex,
};

export const signatureColors = <const>['black', 'blue', 'red'];

export const fontColors = <const>['black', 'blue', 'red', 'white'];

export const fontFamilies = [
  'helvetica',
  'arial',
  'calibri',
  'cambria',
  'courier',
  'georgia',
  'times',
  'trebuchet',
  'verdana',
  'notoSerif',
  'notoSans',
  'roboto',
  'robotoMono',
] as const;
export type SignatureColor = ValueOfArray<typeof signatureColors>;
export type FontColor = ValueOfArray<typeof fontColors>;

export type FontFamily = (typeof fontFamilies)[number];

export { AutoFillType };

export type FieldTypes =
  | 'checkbox'
  | 'date'
  | 'initials'
  | 'signature'
  | 'text'
  | 'dropdown'
  | 'radiobutton'
  | 'hyperlink'
  | 'image'
  | 'rectangle';

export function getAutoFillFieldType(type?: string): AutoFillType | null {
  switch (type) {
    case undefined:
    case null:
      return null;
    case 'name':
      return 'name';
    case 'lastName':
      return 'lastName';
    case 'firstName':
      return 'firstName';
    case 'company':
      return 'company';
    case 'email':
      return 'email';
    case 'title':
      return 'title';
    default:
      throw new Error(`Invalid auto fill type: ${type}`);
  }
}

export type SignatureData = {
  guid: string;
};

export interface BaseField {
  id: string;
  // API ID must be validated in realtime. It can't wait until the user presses
  // "Continue" to be fixed because we rely on using the ID as the key for
  // updating fields. If we did update the ID and then wait until the end to
  // validate, it would be impossible to fix because `updateFields({ [oldID]:
  // {id: newID}})` will update BOTH fields.
  invalidApiId?: string;
  pageIndex: number;
  name?: string;
  x: number;
  y: number;
  required?: boolean;
  // This is used for Conditional Logic. When a required field is hidden,
  // required is stored here as a backup in case the field is shown again.
  originalRequired?: boolean;
  signer: string;
  width: number;
  height: number;
  documentId: string;
  hidden?: boolean;
  readOnly?: boolean;
  // AutoFill
  autoFillType?: AutoFillType | null;
  linkId?: string | null;
  integrationMetadata?: FieldIntegrationMetadata | null;
  label?: string | null;
  formLabel?: string;
}

export type BaseFieldTypes = {
  type: FieldTypes | `${FieldTypes}`;
};

export interface CheckboxField extends BaseField {
  type: 'checkbox';
  checked?: boolean | null;
  group?: string | null;
  requirement?: string | null;
  groupLabel?: string | null;
  isEditableMergeField?: boolean;
  groupFormLabel?: string;
}

export interface RadioField extends BaseField {
  type: 'radiobutton';
  checked: boolean;
  // Radio buttons MUST be part of a group
  group: string;
  requirement: string | null;
  groupLabel?: string | null;
  groupFormLabel?: string;
}

export interface TextField extends BaseField {
  type: 'text';
  placeholder?: string;
  lineHeight?: number;
  fontFamily?: FontFamily;
  fontSize?: number;
  fontColor?: FontColor | null;
  fontWeight?: number;
  value?: string | null;
  validationType?: keyof typeof validationMessageMap | null;
  validationCustomRegexFormatLabel?: string;
  validationCustomRegex?: string;
  masked?: boolean;
  isEditableMergeField?: boolean;
  originalFontSize?: number;
  // `lines` is just `value` with newlines added for explicit word wrapping. We
  // need it because our current Cairo based overlay system doesn't contain a
  // text layout engine, so lines have to be pre-computed.
  lines?: string;
}

export interface DropdownField extends BaseField {
  type: 'dropdown';
  options: Array<string>;
  value?: string | null;
  fontFamily?: FontFamily;
  fontSize?: number;
}

export interface DateField extends BaseField {
  type: 'date';
  placeholder?: string;
  dateFormat?: string | null;
  fontFamily?: FontFamily;
  fontSize?: number;
  value?: string | null;
}

export interface SignatureField extends BaseField {
  type: 'signature';
  color?: (typeof signatureColors)[number] | null;
  signature?: SignatureData;
}

export interface InitialsField extends BaseField {
  type: 'initials';
  color?: (typeof signatureColors)[number] | null;
  signatureColors?: (typeof signatureColors)[number] | null;
  signature?: SignatureData;
}

export interface HyperlinkField extends BaseField {
  type: 'hyperlink';
  lineHeight?: number;
  fontFamily?: FontFamily;
  fontSize?: number;
  value: string | null;
  url: string;
}

export interface RectangleField extends BaseField {
  type: 'rectangle';
  filled: boolean;
}
export interface ImageField extends BaseField {
  type: 'image';
  src: string;
  scale: boolean;
  align?: 'near' | 'middle' | 'far' | null;
}

export type Field =
  | TextField
  | CheckboxField
  | DateField
  | SignatureField
  | InitialsField
  | DropdownField
  | RadioField
  | HyperlinkField
  | RectangleField
  | ImageField;

export type DocumentRotation = 0 | 1 | 2 | 3;
export type Document = {
  id: string;
  name: string;
  snapshotGuid: string;
  editable: boolean;
  pwRequired?: boolean;
  rootSnapshotGuid?: string;
  upload?: {
    progress: number;
    error?: string;
  };
};

export type Page = {
  src: string;
  width: number;
  height: number;
  documentId: string;
  orientation: 0 | 1;
  newOrientation?: 0 | 1;
  cdnSrc?: string | null;
};

export type SigFieldTypes = SignatureField | InitialsField;

export type Signer = {
  id: number | string;
  name: string;
  email: string | null;
  order?: number;
  deleted?: boolean;
  role?: string;
};

export type SignerOrder = {
  [signerId in Signer['id']]: number;
};

export type ValidationError = {
  id: string | null;
  property?: string;
  textId: string;
  values?: {
    name: string;
    signerOrder: number;
  };
};

export type MergeField = {
  name: string;
  type: 'text' | 'checkbox';
  integrationMetadata?: FieldIntegrationMetadata & {
    isTopField?: boolean;
  };
};

export interface FieldOverrideProps {
  autoFillType?: string | null;
  signer?: string | null;
  isDisabled?: boolean;
  name?: string | null;
}

export type FieldIntegrationMetadata = {
  externalName?: string;
  externalType?: string;
};

export type Preparer = {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  company: string;
};

export type IntegrationContext = 'hubspot' | undefined;
