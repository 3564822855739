import * as Yup from 'yup';

import { Actions } from './index';
import { unreachable } from 'js/sign-components/common/ts-utils';
import { UploadIntegrations, uploadIntegrationSchema } from './integration';
import { RequestType as RequestTypes } from 'js/sign-components/generated/types/HelloRequest';
import {
  IntegrationNames,
  integrationNames,
} from 'js/sign-components/common/product-analytics-pipeline';

export const requestTypesSchema = Yup.mixed<RequestTypes>()
  .test(
    'enum',
    // eslint-disable-next-line no-template-curly-in-string
    '${path} invalid enum value',
    (value: unknown): value is RequestTypes => {
      // I can't use an `any` or `unknown` as a key. I'm using this cast to
      // avoid having to ts-ignore the check below.
      const key = value as RequestTypes;

      switch (key) {
        case RequestTypes.SendDoc:
        case RequestTypes.RequestSig:
        case RequestTypes.ResponseSig:
        case RequestTypes.Template:
        case RequestTypes.SendTemplate:
        case RequestTypes.Demo:
        case RequestTypes.FinalizeDocGmail:
        case RequestTypes.ReusableLink:
        case RequestTypes.SelfSave:
        case RequestTypes.EmbeddedRequest:
        case RequestTypes.EmbeddedTemplate:
        case RequestTypes.BulkSend:
        case RequestTypes.EditAndResendDoc:
          return true;
        default:
          unreachable(key);
          return false;
      }
    },
  )
  .required();

type Charges = {
  needsCharge: boolean;
  chargeType: string;
  totalPriceInCents: number;
  freeFaxPagesLeft: number;
  faxPageCurrentCount: number;
  overageFaxMaxLimitInCents: number;
  pricePerFaxInCents: number;
  initialPageLimit: number;
  overagePriceInCentsPerPage: number;
  internationalMultiplier: number;
};

const chargesSchema = Yup.object<Charges>({
  needsCharge: Yup.boolean(),
  chargeType: Yup.string(),
  totalPriceInCents: Yup.number(),
  freeFaxPagesLeft: Yup.number(),
  faxPageCurrentCount: Yup.number(),
  overageFaxMaxLimitInCents: Yup.number(),
  pricePerFaxInCents: Yup.number(),
  initialPageLimit: Yup.number(),
  overagePriceInCentsPerPage: Yup.number(),
  internationalMultiplier: Yup.number(),
});

export { IntegrationNames };

type IntegrationData = {
  externalCacheKey: string;
  externalAppKey: string;
  externalAppPickerSrc: string;
  externalAccountId: string;
  externalFolderPath: string;
  externalFilename?: string;
  externalDefaultSavePath?: string;
  recipientsProvidedByHostPage?: boolean;
};

type DeepIntegration = {
  name:
    | typeof integrationNames.DeepIntegration
    | typeof integrationNames.OfficeSelfSign;
  data: IntegrationData;
};

type SharePointIntegration = {
  name: typeof integrationNames.SharePoint;
  data: IntegrationData;
};

type HubSpotIntegration = {
  name: typeof integrationNames.HubSpot;
  data: IntegrationData;
};

type HubSpotV2Integration = {
  name: typeof integrationNames.HubSpotV2;
  data: IntegrationData;
};

export type Integration =
  | DeepIntegration
  | SharePointIntegration
  | HubSpotIntegration
  | HubSpotV2Integration;

const integrationDataSchema = Yup.object<IntegrationData>({
  externalCacheKey: Yup.string(),
  externalAppKey: Yup.string(),
  externalAppPickerSrc: Yup.string(),
  externalAccountId: Yup.string(),
  externalFolderPath: Yup.string(),
  externalFilename: Yup.string(),
  recipientsProvidedByHostPage: Yup.boolean(),
});

const integrationsSchema = Yup.object<Integration>({
  name: Yup.mixed().oneOf([
    integrationNames.DeepIntegration,
    integrationNames.OfficeSelfSign,
    integrationNames.SharePoint,
    integrationNames.HubSpot,
    integrationNames.HubSpotV2,
  ]),
  data: integrationDataSchema,
});

export type Flags = {
  // Used to initialize a Workflow type. Afterward, read current requestType from
  // Workflow.requestType - use selector getRequestType
  requestType: RequestTypes;
  // Allow user to select a file from third-party website on "Select documents" page
  allowIntegrationsUpload?: boolean;
  // Ability to add signer pin per signer as part of the signature request
  allowRecipientAccessCodes?: boolean;
  // Ability to add signer SMS authentication per signer as part of the signature request
  allowRecipientSmsAuthNumbers?: boolean;
  // Ability to require signers to attach (both required or optional)
  // attachments as part of their signing process
  allowRecipientAttachments?: boolean;
  // Allow recipient reassignment checkbox on "Add signers" page
  allowRecipientReassignment?: boolean;
  // Should this user be given the ability to send Qualified E-Signatures
  allowQualifiedSignature?: boolean;
  // Allow user to enable NOM 151 for the request
  allowNom151?: boolean;
  // This account has access to SMS tools add-on: SMS Delivery + SMS Auth
  allowSmsSignatureDelivery?: boolean;
  // This account has acccess to SMS tools AND it has been turned on via Admin Console SR settings
  isSmsToolsOn?: boolean;
  // User's add-on tier for SMS Signature Delivery can be auto-upgraded
  canUpgradeTierSmsSignatureDelivery?: boolean;
  // Embedded - force showing signer roles
  forceSignerRoles?: boolean;
  // Embedded - force showing subject message
  forceSubjectMessage?: boolean;
  // Hide Stepper icons
  hideProgressStepper?: boolean;
  // Hide entire stepper
  hideStepperContainer?: boolean;
  // Is the account prevented from sending faxes to multiple recipients
  noMultiFaxes?: boolean;
  // User subscription template quota
  maxTemplateNumber?: Number;
  // Is user at their account-type's limit for creating templates
  isAtTemplateLimit?: boolean;
  // Is user at their account-type's limit for sending signature request
  isAtDocumentLimit?: boolean;
  // Is this HelloFax (vs HelloSign)
  isHelloFax?: boolean;
  isEditTemplate?: boolean;
  // If this is coming from a resend or draft flow
  isResend?: boolean;
  // Show modal asking for user first&last name,
  requireSignerInfo?: boolean;
  // User wants a copy of PDF/Fax sent to themselves
  alwaysSendMeACopy?: boolean;
  // User wants a copy of complete/faxed PDF sent to recipients and CCs
  alwaysSendOthersACopy?: boolean;
  // If the fax request needs a one-time charge
  needsCharge?: boolean;
  // Which charge flow the user needs to see
  chargeType?: string;
  // The total price of the charge in cents
  totalPriceInCents?: number;
  // The amount of free faxes the account has left
  freeFaxPagesLeft?: number;
  // The amount of fax pages in the current request
  faxPageCurrentCount?: number;
  // The maximum fax price users can send in a free account
  overageFaxMaxLimitInCents?: number;
  // The normal price for a fax on the account's subscription
  pricePerFaxInCents?: number;
  // How many pages until you are over the initial pricePerFax
  initialPageLimit?: number;
  // How much each page costs after your initial limit
  overagePriceInCentsPerPage?: number;
  // How much the price of a page multiplies based on the receiving country
  internationalMultiplier?: number;
  charge?: Charges;
  // Is third-party (or Dropbox) integration enabled for this request
  integration?: Integration;
  // Includes all integrations for file uploads enabled by the Admin
  uploadIntegrations?: UploadIntegrations;
  blockCC?: boolean;
  lockDocumentTitle?: boolean;
  lockDocumentMessage?: boolean;
  // If the underlying template for edit-and-resend has been modified
  hasTemplateReuseWarning?: boolean;
  // Allow template deletion from P&S workflow
  // TODO: Clean up when template deletion is ready for GA
  allowTemplateDelete: boolean;
  // See if the current account has permission to create/edit templates
  allowCreateEditTemplate: boolean;
  allowTemplatePreview: boolean;
  // Allow template signature requests to have optional signers
  // if at least one signer is populated.
  allowOptionalTemplateSigners: boolean;
  allowSmsAuth?: boolean;
  // The amount of sms authentication requests the account has left
  smsAuthLeft: number;
  allowBulkSend?: boolean;
  // Has a non-Enterprise+ plan that can use recipient access codes regardless of setting
  canAccessRecipientAccessCodes?: boolean;
  // if sub is eligible for doc to template v2 banner
  displayDocToTemplateBanner?: boolean;
  // if user checked doc to template banner checkbox
  shouldConvertDocToTemplate?: boolean;
  // Document to Template conversion ID
  templateConversionCandidateId?: string | null;
  // if sub has HIPAA tag
  hasHipaa?: boolean;
  // Flag that will initiate display of modal that prompts user to create template
  shouldTemplateCreateShow?: boolean | null;
  // Is the plan in context a Blueberry plan?
  isNewHsPlan?: boolean;
  // Is the plan in context a transactional plan
  isTransactionalPlan?: boolean;
  // Used as a feature gate for unlimited self-sign - it will be removed after rollout
  allowUnlimitedSelfSign?: boolean;
  // Used as a feature gate for signature request expiration date
  allowExpiresAt?: boolean;
  apiIdsEnabled?: boolean;
  canUseDataValidation?: boolean;
  canUseCustomRegexDataValidation?: boolean;
  // Indicates that a request originated on Fax
  isFromFax?: boolean;
  // Indicates whether the user has an api plan or access to api
  isApiUser?: boolean;
  // Indicates external signature send is disabled in dropbox
  disableExternalSignature?: boolean;
  canAccessEditAndResendUI?: boolean;
  shouldSendFaxPdfs?: boolean;
  templateGalleryId?: number | null;
};

export const flagsSchema = Yup.object<Flags>({
  requestType: requestTypesSchema,
  allowIntegrationsUpload: Yup.boolean().notRequired(),
  allowRecipientAccessCodes: Yup.boolean().notRequired(),
  allowRecipientSmsAuthNumbers: Yup.boolean().notRequired(),
  allowRecipientAttachments: Yup.boolean().notRequired(),
  allowRecipientReassignment: Yup.boolean().notRequired(),
  allowQualifiedSignature: Yup.boolean().notRequired(),
  allowNom151: Yup.boolean().notRequired(),
  allowSmsSignatureDelivery: Yup.boolean().notRequired(),
  forceSignerRoles: Yup.boolean().notRequired(),
  forceSubjectMessage: Yup.boolean().notRequired(),
  hideProgressStepper: Yup.boolean().notRequired(),
  hideStepperContainer: Yup.boolean().notRequired(),
  noMultiFaxes: Yup.boolean().notRequired(),
  maxTemplateNumber: Yup.number().notRequired(),
  isAtTemplateLimit: Yup.boolean().notRequired(),
  isAtDocumentLimit: Yup.boolean().notRequired(),
  isEditTemplate: Yup.boolean().notRequired(),
  isResend: Yup.boolean().notRequired(),
  isHelloFax: Yup.boolean().notRequired(),
  requireSignerInfo: Yup.boolean().notRequired(),
  alwaysSendMeACopy: Yup.boolean().notRequired(),
  alwaysSendOthersACopy: Yup.boolean().notRequired(),
  needsCharge: Yup.boolean().notRequired(),
  chargeType: Yup.mixed().notRequired(),
  totalPriceInCents: Yup.number(),
  freeFaxPagesLeft: Yup.number(),
  faxPageCurrentCount: Yup.number(),
  overageFaxMaxLimitInCents: Yup.number(),
  pricePerFaxInCents: Yup.number(),
  initialPageLimit: Yup.number(),
  overagePriceInCentsPerPage: Yup.number(),
  internationalMultiplier: Yup.number(),
  charge: chargesSchema.notRequired(),
  integration: integrationsSchema.notRequired(),
  uploadIntegrations: uploadIntegrationSchema.notRequired(),
  hasTemplateReuseWarning: Yup.boolean().notRequired(),
  allowTemplateDelete: Yup.boolean(),
  allowCreateEditTemplate: Yup.boolean(),
  allowTemplatePreview: Yup.boolean(),
  allowOptionalTemplateSigners: Yup.boolean(),
  allowSmsAuth: Yup.boolean().notRequired(),
  smsAuthLeft: Yup.number(),
  allowBulkSend: Yup.boolean().notRequired(),
  canAccessRecipientAccessCodes: Yup.boolean().notRequired(),
  displayDocToTemplateBanner: Yup.boolean().notRequired(),
  shouldConvertDocToTemplate: Yup.boolean().notRequired(),
  templateConversionCandidateId: Yup.string().notRequired().nullable(),
  hasHipaa: Yup.boolean().notRequired(),
  shouldTemplateCreateShow: Yup.boolean().notRequired().nullable(),
  isNewHsPlan: Yup.boolean().notRequired(),
  isTransactionalPlan: Yup.boolean().notRequired(),
  allowUnlimitedSelfSign: Yup.boolean().notRequired(),
  allowExpiresAt: Yup.boolean().notRequired(),
  isFromFax: Yup.boolean().notRequired(),
  disableExternalSignature: Yup.boolean().notRequired(),
  shouldSendFaxPdfs: Yup.boolean().notRequired(),
  templateGalleryId: Yup.number().notRequired().nullable(),
});

export interface SetFlagsAction {
  type: Actions.SetFlags;
  payload: Partial<Flags>;
}

export type FlagsActions = SetFlagsAction;
