import { ThunkAction } from 'hellospa/redux/types';
import delay from 'hellospa/common/utils/delay';
import * as selectors from 'hellospa/page/prep-and-send/data/selectors';
import { unreachable } from 'js/sign-components/common/ts-utils';
import { updateFile } from './file';

const MAX_POLL_COUNT = 100;
const POLL_TIME = NODE_ENV === 'test' ? 1 : 2500;
export const pollEmbedded =
  (): ThunkAction<Promise<void>> => async (dispatch, getState, getExtra) => {
    const { pollEmbeddedFiles } = getExtra().appActions.prepAndSend;
    const guids = selectors.getEmbeddedDocGuids(getState());

    if (!guids) {
      throw new Error('No documents found!');
    }

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < MAX_POLL_COUNT; i++) {
      const data = await pollEmbeddedFiles(guids);

      switch (data.status) {
        case 'ok': {
          const files = selectors.getFiles(getState());
          files.forEach((file) => {
            dispatch(
              updateFile({
                ...file,
                guid: file.guid!, // embedded should already have a file GUID
                status: data.status,
                tsmGroupGuid: selectors.getTransmissionGroupGuid(getState()),
                pageCount: 1, // does this matter?
                pwRequired: false,
              }),
            );
          });
          return;
        }
        case 'error': {
          throw new Error('Unable to load document');
        }

        case 'converting': {
          break;
        }
        default:
          unreachable(data);
      }

      await delay(POLL_TIME);
    }
    /* eslint-enable no-await-in-loop */
  };
