import React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { GlobalHeader } from '@dropbox/dig-components/global_header';
import { Menu } from '@dropbox/dig-components/menu';
import { Button } from '@dropbox/dig-components/buttons';
import { Truncate } from '@dropbox/dig-components/truncate';
import {
  Avatar,
  avatarColorForUserIdentifier,
} from '@dropbox/dig-components/avatar';
import { Text } from '@dropbox/dig-components/typography';
import { LayerContext } from '@dropbox/dig-components/layer';

import { getInitials } from 'hellospa/common/i18n/user';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { SiteCodeType } from 'js/sign-components/common/brand';
import { unreachable } from 'js/sign-components/common/ts-utils';
import SignatureModal from './signature-modal';

import ReferModal from './refer-modal';
import styles from './account-menu.scss';
import SignatureProviderImpl from 'signer-app/signature-modal/signature-modal-context/signature-provider-impl';
import { useIsMobile } from 'js/sign-components/common/hooks';

const messages = defineMessages({
  yourSignature: {
    id: '',
    description: "Title text for an image of the user's signature.",
    defaultMessage: 'Your signature',
  },
  anImageOfYourSignature: {
    id: '',
    description: "Alt text for an image of the user's signature.",
    defaultMessage: 'An image of your signature',
  },
  account: {
    id: '',
    description:
      "Text for a button which shows the user's account info when pressed.",
    defaultMessage: 'Account',
  },
  confirmMessageS: {
    id: 'signModal.saved.delete.confirmMessage.S',
    description:
      'text in signer modal, shows when trying to delete saved signature',
    defaultMessage:
      'Are you sure you want to delete this signature?\nYou will not be able to access it in the future.',
  },
});

type ActionItemValues = 'createOrEditSignature' | 'getFreeDocuments';

export type GlobalHeaderAccountMenuTypes = {
  app?: any;
  siteCode: SiteCodeType;
  locale: string;
  emailAddress: string;
  isFree: boolean;
  numDocsLeft: number | null | 'Unlimited';
  numFaxPagesLeft: number | null | 'Unlimited';
  numApiDocsLeft: number | null | 'Unlimited';
  numTemplatesLeft: number | null | 'Unlimited';
  showApiCredits: boolean;
  canAccessAdminConsole: boolean;
  signatureModalProps?: {
    csrfToken: string;
    signatureType: string;
    settings: {
      firstName?: string;
      lastName?: string;
    };
  };
  isDropboxManaged: boolean;

  // Optional
  name?: string;
  referUrl?: string;
  primarySignatureGuid?: string;
};

declare global {
  interface Window {
    _DBX_ZOOM_CHAT_CLIENT?: {
      showHelloSignCxChat?: () => void;
      handleOnLogoutCallback: () => void;
    };
  }
}

function GlobalHeaderAccountMenu({
  siteCode,
  locale,
  emailAddress,
  name,
  numDocsLeft,
  numFaxPagesLeft,
  numApiDocsLeft,
  numTemplatesLeft,
  primarySignatureGuid: initialPrimarySignatureGuid,
  showApiCredits,
  canAccessAdminConsole,
  signatureModalProps,
  isFree,
  referUrl,
  isDropboxManaged,
}: GlobalHeaderAccountMenuTypes) {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [isSignatureModalOpen, setIsSignatureModalOpen] = React.useState(false);
  const [isReferModalOpen, setIsReferModalOpen] = React.useState(false);
  const site = React.useMemo(
    () => HfReactHelper.HfSites.getSite(siteCode),
    [siteCode],
  );
  const isSign = React.useMemo(
    () => HfReactHelper.HfSites.isHelloSign(site),
    [site],
  );
  const isFax = React.useMemo(
    () => HfReactHelper.HfSites.isHelloFax(site),
    [site],
  );
  const [primarySignatureGuid, setPrimarySignature] = React.useState(
    initialPrimarySignatureGuid,
  );

  // Use initials based on name, or email if no name is set.
  const initials = React.useMemo(() => {
    if (name != null && name.length > 0) {
      return getInitials(name, locale);
    } else {
      return getInitials(emailAddress, locale);
    }
  }, [locale, name, emailAddress]);

  const avatarColor = React.useMemo(
    () => avatarColorForUserIdentifier(initials),
    [initials],
  );

  const onSelection = React.useCallback((value: ActionItemValues) => {
    switch (value) {
      case 'createOrEditSignature':
        setIsSignatureModalOpen(true);
        break;
      case 'getFreeDocuments':
        setIsReferModalOpen(true);
        break;
      default:
        return unreachable(value);
    }
  }, []);

  const handleSignOut = () => {
    if (
      window._DBX_ZOOM_CHAT_CLIENT &&
      typeof window._DBX_ZOOM_CHAT_CLIENT.handleOnLogoutCallback === 'function'
    ) {
      window._DBX_ZOOM_CHAT_CLIENT.handleOnLogoutCallback();
    }
  };

  return (
    <LayerContext.Provider value={{ zIndex: 26 }}>
      {' '}
      {/* Global header is z-index: 25 */}
      <GlobalHeader.AccountMenu
        initials={initials}
        backgroundColor={avatarColor}
        data-qa-ref="global-acct-menu"
        title={intl.formatMessage(messages.account)}
        wrapperActionProps={{
          onSelection,
        }}
      >
        <div className={styles.accountMenu}>
          <Menu.Segment>
            <Menu.Row
              withLeftAccessory={
                <Avatar size="large" backgroundColor={avatarColor}>
                  <span data-qa-ref="global-acct-menu-user-initials">
                    {initials}
                  </span>
                </Avatar>
              }
              withSubtitle={
                <Truncate
                  tooltipControlProps={{ auto: true, placement: 'left' }}
                >
                  <span data-qa-ref="global-acct-menu-user-email">
                    {emailAddress}
                  </span>
                </Truncate>
              }
            >
              {name != null && name.length > 0 && (
                <span data-qa-ref="global-acct-menu-user-name">{name}</span>
              )}
            </Menu.Row>
            {isSign && (
              <>
                <Menu.Row>
                  {showApiCredits && (
                    <div>
                      <Text
                        size="xsmall"
                        color="faint"
                        data-qa-ref="global-acct-menu-api-credits"
                      >
                        {typeof numApiDocsLeft === 'number' ? (
                          <FormattedMessage
                            id=""
                            description="Text which informs the user how many API signature requests they have remaining"
                            defaultMessage="{numApiDocsLeft, plural, =1 {# API signature request} other {# API signature requests}} remaining"
                            values={{
                              numApiDocsLeft,
                            }}
                          />
                        ) : (
                          <FormattedMessage
                            id=""
                            description="Text which informs the user they have unlimited API signature requests"
                            defaultMessage="Unlimited API signature requests remaining"
                          />
                        )}
                      </Text>
                    </div>
                  )}
                  <div>
                    <Text
                      size="xsmall"
                      color="faint"
                      data-qa-ref="global-acct-menu-requests-remaining"
                    >
                      {typeof numDocsLeft === 'number' ? (
                        <FormattedMessage
                          id=""
                          description="Text which informs the user how many signature requests they have remaining"
                          defaultMessage="{numDocsLeft, plural, =1 {# signature request} other {# signature requests}} remaining"
                          values={{
                            numDocsLeft,
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id=""
                          description="Text which informs the user they have unlimited signature requests"
                          defaultMessage="Unlimited signature requests remaining"
                        />
                      )}
                    </Text>
                  </div>
                  <div>
                    <Text
                      size="xsmall"
                      color="faint"
                      data-qa-ref="global-acct-menu-templates-remaining"
                    >
                      {typeof numTemplatesLeft === 'number' ? (
                        <FormattedMessage
                          id=""
                          description="Text which informs the user how many templates they have remaining"
                          defaultMessage="{numTemplatesLeft, plural, =1 {# template} other {# templates}} remaining"
                          values={{
                            numTemplatesLeft,
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id=""
                          description="Text which informs the user they have unlimited templates"
                          defaultMessage="Unlimited templates remaining"
                        />
                      )}
                    </Text>
                  </div>
                </Menu.Row>
                {isFree && referUrl && (
                  <Menu.ActionItem value="getFreeDocuments">
                    <Button
                      variant="transparent"
                      onClick={() => setIsReferModalOpen(true)}
                      data-qa-ref="global-acct-menu-get-free-documents"
                    >
                      <FormattedMessage
                        id=""
                        description="Text for a button that when pressed will bring open the Refer-A-Friend modal."
                        defaultMessage="Get free documents"
                      />
                    </Button>
                    <ReferModal
                      open={isReferModalOpen}
                      referUrl={referUrl}
                      onRequestClose={() => setIsReferModalOpen(false)}
                    />
                  </Menu.ActionItem>
                )}
              </>
            )}

            {isFax && (
              <>
                <Menu.Row>
                  <Text
                    size="small"
                    color="faint"
                    data-qa-ref="global-acct-menu-faxes-remaining"
                  >
                    {typeof numFaxPagesLeft === 'number' ? (
                      <FormattedMessage
                        id=""
                        description="Text which informs the user how many faxes they have remaining"
                        defaultMessage="{numFaxPagesLeft, plural, =1 {# fax page} other {# fax pages}} remaining"
                        values={{
                          numFaxPagesLeft,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id=""
                        description="Text which informs the user they have unlimited faxes"
                        defaultMessage="Unlimited faxes remaining"
                      />
                    )}
                  </Text>
                </Menu.Row>
                <Menu.LinkItem
                  href={HfReactHelper.urlHelper('account/gettingStarted', site)}
                >
                  <Button
                    variant="transparent"
                    href={HfReactHelper.urlHelper(
                      'account/gettingStarted',
                      site,
                    )}
                    data-qa-ref="global-acct-menu-get-started-link"
                  >
                    <FormattedMessage
                      id=""
                      description="Text for a button that when pressed will bring the user to the Getting Started page"
                      defaultMessage="Get free pages"
                    />
                  </Button>
                </Menu.LinkItem>
              </>
            )}
          </Menu.Segment>
          {isSign && (
            <Menu.Segment>
              {primarySignatureGuid && (
                <Menu.Row>
                  <img
                    alt={intl.formatMessage(messages.anImageOfYourSignature)}
                    title={intl.formatMessage(messages.yourSignature)}
                    src={HfReactHelper.urlHelper(
                      `attachment/view?sig_guid=${primarySignatureGuid}`,
                      site,
                    )}
                  />
                </Menu.Row>
              )}
              <Menu.ActionItem value="createOrEditSignature">
                <Button
                  variant="transparent"
                  onClick={() => setIsSignatureModalOpen(true)}
                  data-qa-ref="global-acct-menu-create-or-edit-signature-btn"
                >
                  {primarySignatureGuid ? (
                    <FormattedMessage
                      id=""
                      description="Text for a button that when pressed will guide the user in editing their signature"
                      defaultMessage="Edit signature"
                    />
                  ) : (
                    <FormattedMessage
                      id=""
                      description="Text for a button that when pressed will guide the user in creating their own signature"
                      defaultMessage="Create a signature"
                    />
                  )}
                </Button>
              </Menu.ActionItem>
            </Menu.Segment>
          )}
          <Menu.Segment>
            {/* Admin console */}
            {canAccessAdminConsole && (
              <Menu.LinkItem
                href={HfReactHelper.urlHelper('admin', site)}
                data-qa-ref="global-acct-menu-admin-console-link"
              >
                <Text size="large">
                  <FormattedMessage
                    id=""
                    description="Text for a button that when pressed will bring the user to the Admin Console page"
                    defaultMessage="Admin console"
                  />
                </Text>
              </Menu.LinkItem>
            )}
            {/* Settings */}
            <Menu.LinkItem
              href={HfReactHelper.urlHelper('home/myAccount', site)}
              data-qa-ref="global-acct-menu-settings-link"
            >
              <Text size="large">
                <FormattedMessage
                  id=""
                  description="Text for a button that when pressed will bring the user settings page"
                  defaultMessage="Settings"
                />
              </Text>
            </Menu.LinkItem>
            {/* Manage account */}
            {isDropboxManaged && (
              <Menu.LinkItem
                href="https://www.dropbox.com/account/billing?_tk=hs_manage_acc_multiproduct"
                data-qa-ref="global-acct-menu-manage-account-link"
                target="_blank"
              >
                <Text size="large">
                  <FormattedMessage
                    id=""
                    description="Text for a button that when pressed will bring the user to the Manage Account page"
                    defaultMessage="Manage account"
                  />
                </Text>
              </Menu.LinkItem>
            )}
            {/* Sign out */}
            <Menu.LinkItem
              href={HfReactHelper.urlHelper('account/logOut', site)}
              data-qa-ref="global-acct-menu-sign-out-link"
              onClick={handleSignOut}
            >
              <Text size="large">
                <FormattedMessage
                  id=""
                  description="Text for a button that when pressed will log the user out"
                  defaultMessage="Sign out"
                />
              </Text>
            </Menu.LinkItem>
          </Menu.Segment>
        </div>
      </GlobalHeader.AccountMenu>
      <SignatureProviderImpl
        firstName={signatureModalProps?.settings?.firstName ?? ''}
        lastName={signatureModalProps?.settings?.lastName ?? ''}
        primarySignatureGuid={primarySignatureGuid}
        isEmbedded={false}
        isDropbox={false}
        isMobile={isMobile}
        hideSpinner={false}
        defaultSignatureType={'C'}
        canInsertEverywhere={false}
        confirmDeleteMessage={intl.formatMessage(messages.confirmMessageS)}
        allowColorSig={false}
      >
        <SignatureModal
          isOpen={isSignatureModalOpen}
          onInsert={(signature) => {
            setPrimarySignature(signature?.guid);
            setIsSignatureModalOpen(false);
          }}
          onRequestClose={() => setIsSignatureModalOpen(false)}
        />
      </SignatureProviderImpl>
    </LayerContext.Provider>
  );
}

export default GlobalHeaderAccountMenu;
