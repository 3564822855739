// https://github.com/HelloFax/HelloFax/blob/develop/lib/form/doctrine/TransmissionGroupForm.class.php#L17-L28

import { SignatureActionFlow } from '@dropbox/pap-events/enums/signature_action_flow';
import { RequestType as RequestTypes } from 'js/sign-components/generated/types/HelloRequest';

type SignatureActionFlowMapInterface = {
  [name in RequestTypes]?: SignatureActionFlow;
};

const AnalyticsRequestType: SignatureActionFlowMapInterface = {
  [RequestTypes.RequestSig]: 'send_for_signature',
  [RequestTypes.BulkSend]: 'send_for_signature',
  [RequestTypes.EmbeddedRequest]: 'send_for_signature',
  [RequestTypes.SendTemplate]: 'use_template',
  [RequestTypes.SelfSave]: 'self_sign',
  [RequestTypes.Template]: 'create_template',
};

export { RequestTypes, AnalyticsRequestType };
