import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Button } from '@dropbox/dig-components/buttons';
import { Link, Text } from '@dropbox/dig-components/typography';
import { AccessoryBadge } from '@dropbox/dig-components/badges';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import TextTag, { TextTagType } from 'hellospa/foundation/ui/text-tag';
import Spacer, { SpacerSize } from 'hellospa/foundation/spacing/spacer';
import FormattedFaxNumber from 'common/components/formatted-fax-number';
import { GlobalNavTourItems } from 'hellospa/components/global-nav/global-nav-onboarding-tour';
import BulkSendModal from './bulk-send-modal';
import styles from './global-nav.scss';
import BulkSendTooltip from './bulk-send-tooltip';
import GlobalNavFooter from './global-nav-footer';
import { useFeatureFlag } from 'js/sign-components/common/feature-flag';
import CrossPromoLegalDisclaimerModal from 'hellospa/components/cross-promo-legal-disclaimer-modal';
import {
  ApiDashboardTourItems,
  ApiDashboardLinkTooltip,
} from './api-dashboard-tooltip';
import {
  crossPromoUrl,
  useCrossPromoLegalDisclaimerModal,
} from 'hellospa/components/cross-promo-legal-disclaimer-modal/useCrossPromoLegalDisclaimerModal';
import type { PropsWithSiteCode } from 'js/sign-components/common/brand';
import { ModalThrottleUsage } from 'hellospa/components/modal-throttle-usage/modal-throttle-usage';
import { useDispatch } from 'react-redux';
import { createBannerMessage } from 'hellospa/components/notification-banner/data/actions';
import { useIsMobile, useMatchMedia } from 'js/sign-components/common/hooks';
import { NotificationBannerType } from 'hellospa/components/notification-banner/data/types';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';
import {
  siteCodes,
  getSupportEmailAddress,
} from 'js/sign-components/common/brand';
import { Badge } from '@dropbox/dig-components/badge';
import { PropsOf } from 'js/sign-components/common/ts-utils';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronDownLine, ChevronRightLine } from '@dropbox/dig-icons/assets';
import { useIsTourCompleted } from 'js/sign-components/tour/useTour';
import { useLocation } from 'react-router';

export const sideBarCollapsedMaxWidth = '799px';

export enum NavItemId {
  Home = 'home',
  SignDocuments = 'signdocs',
  SendFax = 'sendfax',
  Templates = 'templates',
  MyTemplates = 'my_templates',
  TemplateGallery = 'template_gallery',
  Documents = 'documents',
  ManageTeam = 'manageteam',
  ManageIntegrations = 'manage_integrations',
  ManageApi = 'manageapi',
  AI = 'ai',
  Forms = 'Forms',
  TemplateConverter = 'templateconverter',
  TransferTemplates = 'transfertemplates',
  GetFreePages = 'getfreepages',
  FilterAllTeam = 'all_team',
  FilterDrafts = 'draft',
  FilterSent = 'sent',
  FilterReceived = 'received',
  SignFaxBridge = 'signfaxbridge',
  FaxSignBridge = 'faxsignbridge',
}

export type HelloFaxFilter =
  | NavItemId.FilterAllTeam
  | NavItemId.FilterDrafts
  | NavItemId.FilterSent
  | NavItemId.FilterReceived;

type FaxLine = {
  number: string;
};

const ActiveItemContext = React.createContext<NavItemId | null>(
  NavItemId.SignDocuments,
);

type NavItemProps = PropsOf<typeof Link> &
  React.PropsWithChildren<{
    id: NavItemId;
    'data-qa-ref': string;
    isBeta?: boolean;
  }>;

function NavItem({
  id,
  children,
  'data-qa-ref': dataQaRef,
  isBeta,
  ...props
}: NavItemProps) {
  const activeItemId = React.useContext(ActiveItemContext);
  const isActive = React.useMemo(() => id === activeItemId, [id, activeItemId]);

  return (
    <div
      className={classnames(styles.navItem, {
        [styles.active]: isActive,
      })}
      data-qa-ref={dataQaRef}
    >
      <Link {...props} hasNoUnderline>
        <Text size="large" className={styles.navItemText}>
          {children}
          {isBeta && (
            <AccessoryBadge className={styles.betaTag}>
              <FormattedMessage
                id=""
                description="A tag next to feature which is currently in beta"
                defaultMessage="Beta"
              />
            </AccessoryBadge>
          )}
        </Text>
      </Link>
    </div>
  );
}

type FilterProps = React.PropsWithChildren<
  PropsWithSiteCode<{
    filter: HelloFaxFilter;
    tagType: TextTagType;
    isActive: boolean;
  }>
>;

function Filter({
  siteCode,
  filter,
  children,
  tagType,
  isActive,
  ...props
}: FilterProps) {
  const site = HfReactHelper.HfSites.getSite(siteCode);
  const url = HfReactHelper.urlHelper(`home/manage?filter=${filter}`, site);

  return (
    <div
      {...props}
      className={classnames(styles.filter, {
        [styles.active]: isActive,
      })}
    >
      <a href={url}>
        <TextTag kind={tagType}>
          <Text className={styles.filterText}>{children}</Text>
        </TextTag>
      </a>
    </div>
  );
}

type GlobalNavProps = React.PropsWithChildren<
  PropsWithSiteCode<{
    canAccessAdminConsole: boolean;
    canAccessBulkSendUI: boolean;
    canAccessTemplateConverter: boolean;
    hasTeamFilter: boolean;
    activeItemId?: NavItemId;
    showApi: boolean;
    canShowApiTooltip?: boolean;
    canAccessForms?: boolean;
    userGuid: string;
    isNewTemplateConverterEnabled: boolean;
    isOldTemplateConverterEnabled: boolean;
    canAccessSignFaxBridge?: boolean;
    canCreateEditTemplate?: boolean;
    isSandBoxedLeoUser?: boolean;

    // App is a complex legacy prop. I don't believe we have
    // any defined types for it. We'll use it with caution
    // here and not make any assumptions about what it can
    // or can't do.
    app: any;
  }>
>;

export function useGlobalNavOpen() {
  const prevClassState = React.useRef(
    document.body.classList.contains('responsive-layout__sidebar-open'),
  );
  const [isOpen, setIsOpen] = React.useState(prevClassState.current);

  const observer = React.useMemo(() => {
    return new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const target = mutation.target as HTMLElement;
        const currentClassState = target.classList.contains(
          'responsive-layout__sidebar-open',
        );
        if (prevClassState.current !== currentClassState) {
          prevClassState.current = currentClassState;

          setIsOpen(currentClassState);
        }
      });
    });
  }, []);

  observer.observe(document.body, { attributeFilter: ['class'] });

  return isOpen;
}

function GlobalNav({
  siteCode,
  activeItemId,
  canAccessAdminConsole,
  canAccessBulkSendUI,
  canAccessTemplateConverter,
  hasTeamFilter,
  showApi,
  canShowApiTooltip,
  canAccessForms,
  app,
  isNewTemplateConverterEnabled,
  isOldTemplateConverterEnabled,
  canAccessSignFaxBridge,
  canCreateEditTemplate,
  isSandBoxedLeoUser,
}: GlobalNavProps) {
  const site = HfReactHelper.HfSites.getSite(siteCode);
  const isSign = HfReactHelper.HfSites.isHelloSign(site);
  const isFax = HfReactHelper.HfSites.isHelloFax(site);
  const isMobile = useIsMobile();
  const isSidebarCollapsible = useMatchMedia(
    `(max-width: ${sideBarCollapsedMaxWidth})`,
  );
  const isDrawerExpanded = useGlobalNavOpen();

  const hasTemplateGallery =
    useFeatureFlag('sign_core_2024_09_03_template_gallery') &&
    canCreateEditTemplate;
  const location = useLocation();
  const [menuState, toggleIsTemplateGalleryMenuOpen] = React.useState(false);
  const isTemplateGalleryMenuOpen =
    menuState ||
    location.pathname.includes('template-gallery') ||
    location.pathname.includes('home/createReusableDocs');

  const currentItemId = React.useMemo(() => {
    if (hasTemplateGallery && isTemplateGalleryMenuOpen) {
      if (location.pathname.includes('home/createReusableDocs')) {
        return NavItemId.MyTemplates; // Saved
      }
      if (location.pathname.includes('template-gallery/browse')) {
        return NavItemId.TemplateGallery; // Gallery
      }
    }

    if (activeItemId) return activeItemId;

    if (['/', '/home', '/home/index'].includes(window.location.pathname)) {
      return NavItemId.Home;
    }

    return null;
  }, [
    activeItemId,
    hasTemplateGallery,
    isTemplateGalleryMenuOpen,
    location.pathname,
  ]);

  const isViewingFaxes = React.useMemo(() => {
    if (!currentItemId) return false;

    return [
      NavItemId.Documents,
      NavItemId.FilterAllTeam,
      NavItemId.FilterSent,
      NavItemId.FilterReceived,
      NavItemId.FilterDrafts,
    ].includes(currentItemId);
  }, [currentItemId]);

  const faxLines = React.useMemo<FaxLine[] | null>(() => {
    if (!isFax) return null;

    if ('getFaxLines' in app.user) {
      return app.user.getFaxLines();
    } else {
      return [];
    }
  }, [isFax, app.user]);

  const [isBulkSendModalOpen, setIsBulkSendUpgradeModalOpen] =
    React.useState(false);

  const blockBulkSendSpamRisk = useFeatureFlag('block_bulk_spam_risk');
  const dispatch = useDispatch();
  const supportEmail = getSupportEmailAddress(siteCodes.sign);

  const onBulkSendPressed = React.useCallback(
    (evt) => {
      const messages = defineMessages({
        bulkSendUnavailable: {
          id: 'bulk_send_unavailable',
          description:
            'notification banner if access to Bulk Send functionality is not allowed',
          defaultMessage:
            'Bulk Send is currently unavailable for your account. Please contact us at {supportEmail} if you need access or have questions.',
        },
      });
      if (blockBulkSendSpamRisk) {
        // Prevent page navigation if user is blocked
        evt.preventDefault();
        dispatch(
          createBannerMessage(
            messages.bulkSendUnavailable,
            NotificationBannerType.Warning,
            { supportEmail },
            { timeout: 7500 },
          ),
        );
        trackHeapCustomEvent('bulk_send_unavailable', {});
      }

      if (!canAccessBulkSendUI) {
        // Prevent page navigation if user does not have
        // access to Bulk Send.
        evt.preventDefault();

        // Show upsell modal instead.
        setIsBulkSendUpgradeModalOpen(true);
      }
    },
    [blockBulkSendSpamRisk, dispatch, supportEmail, canAccessBulkSendUI],
  );

  const [showThrottleModal, setShowThrottleModal] = React.useState(false);

  const handleSignDocumentsClick = React.useCallback(() => {}, []);
  const handleModalClose = React.useCallback(() => {
    setShowThrottleModal(false);
  }, [setShowThrottleModal]);

  const onBulkSendModalRequestClose = React.useCallback(() => {
    setIsBulkSendUpgradeModalOpen(false);
  }, []);

  const bulkSendTriggerRef = React.useRef<HTMLDivElement>(null);

  const crossPromoLegalDisclaimerModel = useCrossPromoLegalDisclaimerModal();

  const showFormsNavItem =
    useFeatureFlag('sign_services_2022_06_28_hs_hw_cross_promo_integration') ||
    canAccessForms;

  const galleryTourComplete = useIsTourCompleted(
    'template-gallery-templates-page-tour',
  );

  return (
    <ActiveItemContext.Provider value={currentItemId}>
      <div
        id="global-nav__drawer"
        className={styles.globalNav}
        {...(isSidebarCollapsible && {
          'aria-expanded': isDrawerExpanded,
        })}
      >
        <div className={styles.actionButtons}>
          {isSign && !isSandBoxedLeoUser && (
            <>
              <Button
                variant="primary"
                size="large"
                data-qa-ref="global-nav-sign-documents-btn"
                data-tour-item={GlobalNavTourItems.signDocuments}
                href={HfReactHelper.urlHelper('prep-and-send', site)}
                onClick={handleSignDocumentsClick}
              >
                <FormattedMessage
                  id=""
                  description="Text for a button which redirects user to the Sign Documents page when pressed."
                  defaultMessage="Sign documents"
                />
              </Button>
              <Button
                variant="opacity"
                size="large"
                data-qa-ref="global-nav-bulk-send-btn"
                href={HfReactHelper.urlHelper('prep-and-send/new/13', site)}
                onClick={onBulkSendPressed}
                ref={bulkSendTriggerRef}
              >
                <FormattedMessage
                  id=""
                  description="Text for a button which redirects user to the Sign Documents page when pressed."
                  defaultMessage="Bulk send"
                />
              </Button>
              {!isMobile && canAccessBulkSendUI && !blockBulkSendSpamRisk && (
                <BulkSendTooltip triggerRef={bulkSendTriggerRef} />
              )}
              {!canAccessBulkSendUI && (
                <BulkSendModal
                  open={isBulkSendModalOpen}
                  onRequestClose={onBulkSendModalRequestClose}
                />
              )}
            </>
          )}
          {isFax && (
            <>
              <Button
                variant="primary"
                size="large"
                data-qa-ref="global-nav-send-fax-btn"
                href={HfReactHelper.urlHelper('prep-and-send', site)}
              >
                <FormattedMessage
                  id=""
                  description="Text for a button which redirects user to the Send a Fax page when pressed."
                  defaultMessage="Send faxes"
                />
              </Button>
            </>
          )}
        </div>
        <div data-qa-ref="global-nav-links">
          <NavItem
            id={NavItemId.Home}
            data-qa-ref="global-nav-home-link"
            href="/"
          >
            <FormattedMessage
              id=""
              description="Text for a link which redirects user to the homepage when pressed."
              defaultMessage="Home"
            />
          </NavItem>

          {isSign && !hasTemplateGallery && (
            <NavItem
              id={NavItemId.Templates}
              data-qa-ref="global-nav-templates-link"
              href={HfReactHelper.urlHelper('home/createReusableDocs', site)}
            >
              <span data-tour-item={GlobalNavTourItems.templates}>
                <FormattedMessage
                  id=""
                  description="Text for a link which redirects user to the Templates page when pressed."
                  defaultMessage="Templates"
                />
              </span>
            </NavItem>
          )}

          {isSign && hasTemplateGallery && (
            <div className={styles.templateNavItem}>
              <NavItem
                id={NavItemId.Templates}
                data-qa-ref="global-nav-templates-link"
                href={HfReactHelper.urlHelper('home/createReusableDocs', site)}
              >
                <span data-tour-item={GlobalNavTourItems.templates}>
                  <FormattedMessage
                    id=""
                    description="Text for a link which redirects user to the Templates page when pressed."
                    defaultMessage="Templates"
                  />
                </span>
              </NavItem>
              <Button
                variant="transparent"
                onClick={() => {
                  toggleIsTemplateGalleryMenuOpen(!isTemplateGalleryMenuOpen);
                }}
                hasNoUnderline={true}
              >
                <UIIcon
                  size="small"
                  src={
                    isTemplateGalleryMenuOpen
                      ? ChevronDownLine
                      : ChevronRightLine
                  }
                  className={styles.chevron}
                />
              </Button>
            </div>
          )}

          {isSign && hasTemplateGallery && isTemplateGalleryMenuOpen && (
            <div className={styles.templateNavSubItems}>
              <NavItem
                id={NavItemId.MyTemplates}
                data-qa-ref="global-nav-my-templates-link"
                href={HfReactHelper.urlHelper('home/createReusableDocs', site)}
              >
                <span data-tour-item={GlobalNavTourItems.templates}>
                  <FormattedMessage
                    id=""
                    description="Text for a link which redirects user to the Templates page when pressed."
                    defaultMessage="Saved"
                  />
                </span>
              </NavItem>

              <NavItem
                id={NavItemId.TemplateGallery}
                data-qa-ref="global-nav-templates-link"
                href={HfReactHelper.urlHelper('template-gallery/browse', site)}
              >
                <span data-tour-item={GlobalNavTourItems.templates}>
                  <FormattedMessage
                    id=""
                    description="Text for a link which redirects user to the Template gallery page when pressed."
                    defaultMessage="Gallery"
                  />
                </span>
                {!galleryTourComplete && (
                  <Badge variant="standard" size="xsmall" over="subtle">
                    <FormattedMessage
                      id=""
                      description="Badge that indicates that this section is new"
                      defaultMessage="New"
                    />
                  </Badge>
                )}
              </NavItem>
            </div>
          )}

          <NavItem
            id={NavItemId.Documents}
            data-qa-ref="global-nav-documents-link"
            href={HfReactHelper.urlHelper('home/manage', site)}
          >
            {isSign && (
              <FormattedMessage
                id=""
                description="Text for a link which redirects user to the Documents page when pressed."
                defaultMessage="Documents"
              />
            )}
            {isFax && (
              <FormattedMessage
                id=""
                description="Text for a link which redirects user to the Faxes page when pressed."
                defaultMessage="Faxes"
              />
            )}
          </NavItem>

          {isFax && isViewingFaxes && (
            <div
              className={styles.filtersList}
              data-qa-ref="global-nav-filter-list"
            >
              {hasTeamFilter && (
                <Filter
                  siteCode={siteCode}
                  filter={NavItemId.FilterAllTeam}
                  tagType={TextTagType.Info}
                  isActive={currentItemId === NavItemId.FilterAllTeam}
                  data-qa-ref="global-nav-filter-all-team"
                >
                  <FormattedMessage
                    id=""
                    description="Text for a link which shows all faxes sent by everyone on the team when pressed."
                    defaultMessage="All team faxes"
                  />
                </Filter>
              )}
              <Filter
                siteCode={siteCode}
                filter={NavItemId.FilterSent}
                tagType={TextTagType.Success}
                isActive={currentItemId === NavItemId.FilterSent}
                data-qa-ref="global-nav-filter-sent"
              >
                <FormattedMessage
                  id=""
                  description="Text for a link which shows all documents sent by the user when pressed."
                  defaultMessage="Sent"
                />
              </Filter>
              <Filter
                siteCode={siteCode}
                filter={NavItemId.FilterReceived}
                tagType={TextTagType.Info}
                isActive={currentItemId === NavItemId.FilterReceived}
                data-qa-ref="global-nav-filter-received"
              >
                <FormattedMessage
                  id=""
                  description="Text for a link which shows all documents received by the user when pressed."
                  defaultMessage="Received"
                />
              </Filter>
              <Filter
                siteCode={siteCode}
                filter={NavItemId.FilterDrafts}
                tagType={TextTagType.Neutral}
                isActive={currentItemId === NavItemId.FilterDrafts}
                data-qa-ref="global-nav-filter-drafts"
              >
                <FormattedMessage
                  id=""
                  description="Text for a link which shows all document drafts owned by the user when pressed."
                  defaultMessage="Drafts"
                />
              </Filter>
            </div>
          )}

          {/* Show Teams link only if user cannot access admin console. */}
          {!canAccessAdminConsole && !isSandBoxedLeoUser && (
            <NavItem
              id={NavItemId.ManageTeam}
              data-qa-ref="global-nav-teams-link"
              href={HfReactHelper.urlHelper('home/team', site)}
            >
              <FormattedMessage
                id=""
                description="Text for a link which redirects user to the Teams page when pressed."
                defaultMessage="Teams"
              />
            </NavItem>
          )}

          <NavItem
            id={NavItemId.ManageIntegrations}
            data-qa-ref="global-nav-integrations-link"
            href={HfReactHelper.urlHelper(
              'home/myAccount?current_tab=integrations',
              site,
            )}
          >
            <span data-tour-item={GlobalNavTourItems.integrations}>
              <FormattedMessage
                id=""
                description="Text for a link which redirects user to the Integrations settings page when pressed."
                defaultMessage="Integrations"
              />
            </span>
          </NavItem>
          {isSign && showApi && (
            <>
              <NavItem
                id={NavItemId.ManageApi}
                data-qa-ref="global-nav-api-link"
                href={HfReactHelper.urlHelper(
                  'home/myAccount?current_tab=api',
                  site,
                )}
              >
                <span data-tour-item={ApiDashboardTourItems.apiNavLink}>
                  <FormattedMessage
                    id=""
                    description="Text for a link which redirects user to the API settings page when pressed."
                    defaultMessage="API"
                  />
                </span>
              </NavItem>
              {canShowApiTooltip && <ApiDashboardLinkTooltip />}
            </>
          )}

          {/* Show template converter only if the user has access to it. */}
          {/* (Requires the "template conversion" account tag) */}
          {isSign &&
            (canAccessTemplateConverter || isOldTemplateConverterEnabled) &&
            !isNewTemplateConverterEnabled && (
              <NavItem
                id={NavItemId.TemplateConverter}
                data-qa-ref="global-nav-convert-template-link"
                href={HfReactHelper.urlHelper('template/converter', site)}
                isBeta={true}
              >
                <>
                  <FormattedMessage
                    id=""
                    description="Text for a link which redirects user to the Template Converter tool when pressed. The template converter converts users' templates from a competitor to our product's templates."
                    defaultMessage="Convert template"
                  />
                </>
              </NavItem>
            )}

          {isSign && isNewTemplateConverterEnabled && (
            <NavItem
              id={NavItemId.TransferTemplates}
              data-qa-ref="global-nav-transfer-templates-link"
              href={HfReactHelper.urlHelper('home/transferTemplates', site)}
            >
              <FormattedMessage
                id=""
                description="Text for a link which redirects user to the Transfer Template tool when pressed. The transfer template converts users' templates from a competitor to our product's templates."
                defaultMessage="Migrate templates"
              />
            </NavItem>
          )}

          {showFormsNavItem && (
            <div className={`pt-5 ${styles.formsLink}`}>
              <NavItem
                id={NavItemId.Forms}
                data-qa-ref="global-nav-forms-link"
                isBeta={true}
                href={crossPromoUrl}
                onClick={crossPromoLegalDisclaimerModel.showModalIfUnseen}
                target="_blank"
              >
                <FormattedMessage
                  id=""
                  description="Text for a link which redirects the user to a tool for creating and sending forms."
                  defaultMessage="Forms"
                />
              </NavItem>
              <CrossPromoLegalDisclaimerModal
                open={
                  crossPromoLegalDisclaimerModel.isCrossPromoLegalDisclaimerModelOpen
                }
                onAfterOpen={crossPromoLegalDisclaimerModel.openCrossPromo}
              />
            </div>
          )}

          {isFax && (
            <NavItem
              id={NavItemId.GetFreePages}
              data-qa-ref="global-nav-get-started-link"
              href={HfReactHelper.urlHelper('account/gettingStarted', site)}
            >
              <FormattedMessage
                id=""
                description="Text for a link which redirects user to the Getting Started page when pressed. This page will allow them to share the product to increase their Fax Page quota."
                defaultMessage="Get free pages"
              />
            </NavItem>
          )}

          {isSign && canAccessSignFaxBridge && (
            <NavItem
              id={NavItemId.SignFaxBridge}
              data-qa-ref="global-nav-fax-sign-bridge-link"
              href={HfReactHelper.urlHelper('signFax/bridge', site)}
            >
              <FormattedMessage
                id=""
                description="Text for a link which redirects user to the page where they can send fax."
                defaultMessage="Send fax"
              />
              <Badge variant="standard" size="xsmall" over="subtle">
                <FormattedMessage
                  id=""
                  description="Badge that indicates that this section is new"
                  defaultMessage="New"
                />
              </Badge>
            </NavItem>
          )}

          {isFax && (
            <NavItem
              id={NavItemId.FaxSignBridge}
              data-qa-ref="global-nav-sign-fax-bridge-link"
              href={HfReactHelper.urlHelper('signFax/bridge', site)}
            >
              <FormattedMessage
                id=""
                description="Text for a link which redirects user to the page where they can sign documents when pressed."
                defaultMessage="Sign documents"
              />
            </NavItem>
          )}

          {isFax && !!faxLines && faxLines.length > 0 && (
            <div className={styles.faxLines} data-qa-ref="global-nav-fax-lines">
              <Text isBold color="faint">
                <FormattedMessage
                  id=""
                  description="Header text for a list of fax numbers."
                  defaultMessage="Your fax numbers"
                />
              </Text>
              <Spacer top={SpacerSize.SM} />
              {faxLines.map(({ number }) => (
                <Spacer bottom={SpacerSize.XS} key={number}>
                  <Text data-qa-ref="global-nav-fax-line">
                    <FormattedFaxNumber faxNumber={number} />
                  </Text>
                </Spacer>
              ))}
            </div>
          )}
        </div>
        <GlobalNavFooter siteCode={siteCode} />
      </div>
      {showThrottleModal && (
        <ModalThrottleUsage handleModalClose={handleModalClose} />
      )}
    </ActiveItemContext.Provider>
  );
}

export default GlobalNav;
