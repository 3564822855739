import * as Yup from 'yup';

import * as yupUtils from 'hellospa/common/utils/yup';
import { Actions } from './index';
import { defineMessages } from 'react-intl';
import { intl } from 'hellospa/common/hs-intl-provider';
import { Meta } from './meta';
import {
  validateEmail,
  asciiRegex,
  asciiMessage,
} from '../utils/validate-email';

const messages = defineMessages({
  attachmentName: {
    id: '',
    defaultMessage: 'Attachment name',
    description:
      'Label for input fields that gets injected into error messages',
  },
  aSigningRole: {
    id: '',
    defaultMessage: 'A signing role',
    description:
      'Label for input fields that gets injected into error messages',
  },
  attachmentNames: {
    id: '',
    defaultMessage: 'Attachment names',
    description:
      'Label for input fields that gets injected into error messages',
  },
  roles: {
    id: '',
    defaultMessage: 'Roles',
    description:
      'Label for input fields that gets injected into error messages',
  },
  aSignerName: {
    id: '',
    defaultMessage: 'A signer name',
    description:
      'Label for input fields that gets injected into error messages',
  },
  anEmailAddress: {
    id: '',
    defaultMessage: 'An email address',
    description:
      'Label for input fields that gets injected into error messages',
  },
  aFaxNumberOrEmailAddress: {
    id: '',
    defaultMessage: 'A fax number or email address is required',
    description:
      'Label for input fields that gets injected into error messages',
  },
  uniqueNameAndEmail: {
    id: '',
    defaultMessage: 'Signer name and email combinations',
    description:
      'Label for input fields that gets injected into error messages',
  },
  recipients: {
    id: '',
    defaultMessage: 'Recipients',
    description:
      'Label for input fields that gets injected into error messages',
  },
  titleNotEmailOrURL: {
    id: '',
    defaultMessage:
      'URLs and email addresses are not permitted in document titles',
    description: "Error Instructing that URL or Emails can't be document title",
  },
  nameRequired: {
    id: '',
    defaultMessage: 'A signer name is required',
    description:
      "Error message to be displayed when signer's name is not provided",
  },
  emailRequired: {
    id: '',
    defaultMessage: 'An email address is required',
    description:
      "Error message to be displayed when signer's email address is not provided",
  },
  validEmailRequired: {
    id: '',
    defaultMessage: 'A valid email address is required',
    description:
      "Error message to be displayed when signer's email address is not a valid email address",
  },
  nameMaxLimit: {
    id: '',
    defaultMessage: 'Name must be at most 128 characters',
    description:
      "Error message to be displayed when signer's name is longer than 128 characters",
  },
  emailMaxLimit: {
    id: '',
    defaultMessage: 'Email must be at most 100 characters',
    description:
      "Error message to be displayed when signer's email address is longer than 100 characters",
  },
  accessCodeRequired: {
    id: '',
    defaultMessage: 'Access code is required',
    description:
      "Error message to be displayed when signer's access code is not provided",
  },
  smsNumberRequired: {
    id: '',
    defaultMessage: 'Phone number is required',
    description:
      "Error message to be displayed when signer's phone number is not provided",
  },
});

export const recipientTypes = {
  Signer: 'signer',
  Role: 'role',
  Fax: 'fax',
} as const;
export type RecipientTypes =
  (typeof recipientTypes)[keyof typeof recipientTypes];

export enum RecipientDeliveryTypes {
  Email = 'EMAIL',
  EmailAndSms = 'EMAIL-SMS',
}

export interface Attachment {
  readonly id: string;
  readonly name: string;
  readonly instructions?: string;
  readonly required: boolean;
}

interface BaseRecipient {
  readonly id: string;
}

export type AttachmentsKeyed = Partial<Record<Attachment['id'], Attachment>>;

export interface Signer extends BaseRecipient {
  readonly type: typeof recipientTypes.Signer;
  readonly id: string;
  readonly email: string;
  readonly name: string;
  readonly role?: Role | null;
  readonly attachments: Attachment[];
  readonly accessCode: string;
  readonly smsAuthNumber: string;
  readonly smsDeliveryMobileNumber: string;
  readonly deliveryType: string;
  readonly authType?: string;
  readonly order?: number;
}

type SignerKeyed = Omit<Signer, 'attachments'> & {
  readonly attachments: AttachmentsKeyed;
};

export interface Role extends BaseRecipient {
  readonly type: typeof recipientTypes.Role;
  readonly name: string;
  readonly attachments: Attachment[];
}

type RoleKeyed = Omit<Role, 'attachments'> & {
  readonly attachments: AttachmentsKeyed;
};

export interface FaxRecipient extends BaseRecipient {
  readonly type: typeof recipientTypes.Fax;
  readonly fax: string;
}

export type Recipient = Signer | Role | FaxRecipient;

export type RecipientKeyed = SignerKeyed | RoleKeyed | FaxRecipient;

// Only show validation errors to user for these properties
export type RecipientErrorable =
  | 'recipients'
  | keyof Pick<Signer, 'name' | 'email' | 'accessCode' | 'smsAuthNumber'>
  | keyof Pick<Role, 'name'>
  | keyof Pick<FaxRecipient, 'fax'>;

export const accessCodeModalSchema = Yup.lazy(
  (value: string): Yup.StringSchema => {
    const schema = Yup.string().trim().max(12);

    // if string is empty, allow to pass
    return !value || !value.length ? schema : schema.min(4);
  },
);

export const accessCodeSchema = Yup.string().when('authType', {
  is: 'accessCode',
  then: Yup.string()
    .trim()
    .min(4)
    .max(12)
    .required(intl.formatMessage(messages.accessCodeRequired)),
});

export const smsAuthNumberSchema = Yup.string().when('authType', {
  is: 'smsAuthNumber',
  then: yupUtils
    .phoneNumber()
    .required(intl.formatMessage(messages.smsNumberRequired)),
});

export const smsDeliveryMobileNumberSchema = Yup.string().when('deliveryType', {
  is: RecipientDeliveryTypes.EmailAndSms,
  then: yupUtils
    .phoneNumber()
    .required(intl.formatMessage(messages.smsNumberRequired)),
});

export const roleNameSchema = Yup.lazy((value: string): Yup.StringSchema => {
  const nameInputSchema = Yup.string().trim();
  const name = Yup.string()
    .trim()
    .label(intl.formatMessage(messages.aSigningRole))
    .required()
    .max(128);
  const nameSchema = Yup.string()
    .trim()
    .test(
      'Not email or Url',
      intl.formatMessage(messages.titleNotEmailOrURL),
      (name) => {
        const validUrl = nameInputSchema.url().isValidSync(name);
        const validEmail = nameInputSchema.email().isValidSync(name);

        return !validUrl && !validEmail;
      },
    );

  // if string is empty, allow to pass
  return !value || !value.length ? name : nameSchema.max(128);
});

export const MAX_ATTACHMENT_LENGTH = 150;
export const attachmentSchema = Yup.object<Attachment>({
  id: Yup.string().required(),
  name: Yup.string()
    .trim()
    .label(intl.formatMessage(messages.attachmentName))
    .required()
    .max(MAX_ATTACHMENT_LENGTH),
  instructions: Yup.string().trim().max(1000).default(''),
  required: Yup.boolean().default(false),
});

const roleSchemaDefinition = {
  type: Yup.mixed().oneOf([recipientTypes.Role]),
  id: Yup.string().required(),
  name: roleNameSchema,
  attachments: Yup.array()
    .ensure()
    .of(attachmentSchema)
    .label(intl.formatMessage(messages.attachmentNames)),
};

export const roleSchema = Yup.object<Role>(roleSchemaDefinition);

export const multiRoleSchema = Yup.object().shape({
  recipients: Yup.array()
    .of(roleSchema)
    .label(intl.formatMessage(messages.roles))
    .unique(['name'])
    .min(1)
    .required(),
});

export const signerSchema = Yup.object<Signer>({
  // I have to include all props to make TypeScript and yup agree
  type: Yup.mixed().oneOf([recipientTypes.Signer]),
  id: Yup.string().required(),
  name: Yup.string()
    .trim()
    .label(intl.formatMessage(messages.aSignerName))
    .required(intl.formatMessage(messages.nameRequired))
    .max(128, intl.formatMessage(messages.nameMaxLimit)),
  role: Yup.object().nullable().shape(roleSchemaDefinition).default(null),
  // Yup.ValidationError.type is undefined when email is invalid, so I'm falling
  // back to overriding the message.
  email: Yup.string()
    .trim()
    .label(intl.formatMessage(messages.anEmailAddress))
    .sequence([
      () => Yup.string().email(intl.formatMessage(messages.validEmailRequired)),
      () => Yup.string().required(intl.formatMessage(messages.emailRequired)),
      () => Yup.string().max(100, intl.formatMessage(messages.emailMaxLimit)),
      () =>
        Yup.string().matches(
          asciiRegex,
          intl.formatMessage(asciiMessage.noAscci),
        ),
      (testContext: Yup.TestContext) =>
        Yup.string().test(
          'email',
          'Email Validation Error',
          async (value: string) => {
            return validateEmail(testContext, value);
          },
        ),
    ]),
  // eslint-disable-next-line func-names
  accessCode: accessCodeSchema,
  smsAuthNumber: smsAuthNumberSchema,
  smsDeliveryMobileNumber: smsDeliveryMobileNumberSchema,
  deliveryType: Yup.string(),
  attachments: Yup.array()
    .ensure()
    .of(attachmentSchema)
    .label(intl.formatMessage(messages.attachmentNames))
    .unique(['name']),
});

export const multiSignerSchema = Yup.object().shape({
  recipients: Yup.array()
    .of(signerSchema)
    .label(intl.formatMessage(messages.uniqueNameAndEmail))
    // The combination of name&email must be unique. It's valid for two
    // recipients to have the same email as long as they have different names.
    .unique(['name', 'email'])
    .min(1)
    .required(),
});

export const multiSignerRoleSchema = Yup.object().shape({
  recipients: Yup.array()
    .of(signerSchema)
    .label(intl.formatMessage(messages.uniqueNameAndEmail))
    .removeEmpty(['name', 'email'])
    // The combination of name&email must be unique. It's valid for two
    // recipients to have the same email as long as they have different names.
    .unique(['name', 'email']),
});

export const faxSchema = Yup.object<FaxRecipient>({
  type: Yup.mixed().oneOf([recipientTypes.Fax]),
  id: Yup.string().required(),
  fax: Yup.lazy(
    (value: string): Yup.StringSchema =>
      value && value.includes('@')
        ? Yup.string().email().max(100)
        : yupUtils
            .phoneNumber()
            .required(intl.formatMessage(messages.aFaxNumberOrEmailAddress)),
  ),
});

export const multiFaxSchema = Yup.object().shape({
  recipients: Yup.array()
    .of(faxSchema)
    .label(intl.formatMessage(messages.recipients))
    .unique(['fax'])
    .min(1)
    .required(),
});

const recipientUnionSchema = {
  [recipientTypes.Signer]: signerSchema,
  [recipientTypes.Role]: roleSchema,
  [recipientTypes.Fax]: faxSchema,
} as const;

export const recipientSchema = yupUtils.lazyUnion<
  yupUtils.InferUnion<typeof recipientUnionSchema>,
  'type'
>('type', recipientUnionSchema);

export interface CreateRecipientAction {
  type: Actions.CreateRecipient;
  payload: Recipient;
}

export interface UpdateRecipientAction {
  type: Actions.UpdateRecipient;
  payload: {
    id: Recipient['id'];
    updates: Partial<Recipient>;
  };
}

export interface DeleteRecipientAction {
  type: Actions.DeleteRecipient;
  payload: Recipient['id'];
}

export interface ResetRecipients {
  type: Actions.ResetRecipients;
  payload: {
    recipients: Recipient[];
  };
  meta: Partial<Meta>;
}

export interface CreateAttachmentAction {
  type: Actions.CreateAttachment;
  payload: {
    signerId: Recipient['id'];
    attachment: Attachment;
  };
}

export interface UpdateAttachmentAction {
  type: Actions.UpdateAttachment;
  payload: {
    signerId: Recipient['id'];
    id: Attachment['id'];
    updates: Partial<Attachment>;
  };
}

export interface DeleteAttachmentAction {
  type: Actions.DeleteAttachment;
  payload: {
    signerId: Recipient['id'];
    id: Attachment['id'];
  };
}

export type RecipientActions =
  | CreateRecipientAction
  | UpdateRecipientAction
  | DeleteRecipientAction
  | ResetRecipients
  | CreateAttachmentAction
  | UpdateAttachmentAction
  | DeleteAttachmentAction;
