import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

// DIG
import { Text } from '@dropbox/dig-components/typography';
import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Tooltip } from '@dropbox/dig-components/tooltips';

// Form
import CancelReasonCost from './form/cost';
import CancelReasonTemporary from './form/temporary';
import CancelReasonAlternative from './form/alternative';
import CancelReasonFeatures from './form/features';
import CancelReasonTechnical from './form/technical';
import CancelReasonOther from './form/other';
import { values } from './form/types';

import styles from './survey.module.scss';
import classNames from 'classnames';
import { PauseCancelModalContext, SafeDowngradeStatusEnum } from '../types';
import { cancelSubscription, submitSurvey } from '../utils';
import { link } from '../translation-utils';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { errors, modalTypes } from '../constants';
import Spacer, { SpacerSize } from '../../../foundation/spacing/spacer';
import { logPAPEvent } from 'js/sign-components/common/product-analytics-pipeline';
import { PAP_View_CancelSubscription } from '@dropbox/pap-events/sign_standalone/view_cancel_subscription';
import { PAP_Select_CancelSubscription } from '@dropbox/pap-events/sign_standalone/select_cancel_subscription';

type SurveyProps = {
  setModalType: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<string | null>>;
  modalContext: PauseCancelModalContext;
  handleCloseModal: () => void;
  currentStatus: SafeDowngradeStatusEnum | null;
};

const Survey = ({
  modalContext,
  setModalType,
  setError,
  handleCloseModal,
  currentStatus,
}: SurveyProps) => {
  const { siteCode, planToCancel, planName } = modalContext;
  const [reason, setReason] = React.useState('');
  const [showReasonRequiredError, setShowReasonRequiredError] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);
  const contextRef: React.Ref<any> = useRef();

  const termsOfServiceLink = HfReactHelper.getWebflowUrl('terms');

  const onReasonChange = useCallback((evt) => {
    setReason(evt.currentTarget.value);
    setShowReasonRequiredError(false);
  }, []);

  const onSubmitSurveyAndCancel = useCallback(async () => {
    let context = '';
    if (contextRef.current) {
      context = contextRef.current.value.trim();
    }

    await submitSurvey({ reason, planToCancel, context });
    const response = await cancelSubscription(planToCancel);

    if (response.success) {
      logPAPEvent(
        PAP_Select_CancelSubscription({
          signCancelSubscriptionExperience:
            'pause_or_cancel_subscription_modal',
        }),
      );
      if (response.message) {
        window.location.reload();
      } else {
        setError(errors.CANCELLATION);
        setModalType(modalTypes.ERROR);
      }
    }
    setIsCancelling(false);
    handleCloseModal();
  }, [reason, planToCancel, handleCloseModal, setError, setModalType]);

  const reasons = React.useMemo(() => {
    return Object.entries({
      [values.TEMPORARY]: CancelReasonTemporary,
      [values.COST]: CancelReasonCost,
      [values.FEATURES]: CancelReasonFeatures,
      [values.ALTERNATIVE]: CancelReasonAlternative,
      [values.TECHNICAL]: CancelReasonTechnical,
    });
  }, []);

  const handleOpenPauseModal = () => {
    setModalType(modalTypes.PAUSE);
  };

  useEffect(() => {
    logPAPEvent(
      PAP_View_CancelSubscription({
        signCancelSubscriptionExperience: 'pause_or_cancel_subscription_modal',
      }),
    );
  }, []);

  return (
    <div className={styles.survey}>
      <Modal.Header
        hasBottomSpacing="title-standard"
        className={styles.testing}
      >
        <Modal.Title
          id="cancellation-flow-modal-title"
          className={styles.header}
        >
          <FormattedMessage
            id=""
            description="Header asking the user to provide more information as to why they are cancelling their subscription."
            defaultMessage="One more thing before you go: Please share with us why you decided to cancel today."
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.surveyBody}>
          <Text className={styles.ctaText}>
            <FormattedMessage
              id=""
              description="Text followed by a list of options which checkboxes to choose from."
              defaultMessage="Select one option below:"
            />
          </Text>
          <div className={styles.reasonList}>
            {/* These are randomly arranged each time users visit this page. */}
            {reasons.map(([reasonType, Reason]) => (
              <Reason
                key={reasonType}
                siteCode={siteCode}
                disabled={isCancelling}
                checked={reasonType === reason}
                onChange={onReasonChange}
                contextRef={contextRef}
              />
            ))}
            {/* "Other" is always last. */}
            <CancelReasonOther
              siteCode={siteCode}
              disabled={isCancelling}
              checked={reason === values.OTHER}
              onChange={onReasonChange}
              contextRef={contextRef}
            />
          </div>
        </div>
        <Text className={styles.disclaimer}>
          <Spacer top={SpacerSize.LG} />
          <FormattedMessage
            id=""
            description="billboard modal, disclaimer message that informs users how long their subscription will last after cancellation"
            defaultMessage="By canceling, you will retain access to {planName} features through the remainder of the period already paid. When this expires, you will lose access to these features. You will not receive a free trial if you choose to sign up for a new subscription. View our <l1>Terms of Service</l1> for more details."
            values={{
              l1: (chunks: any[]) => link(chunks, termsOfServiceLink),
              planName,
            }}
          />
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="transparent"
          onClick={handleCloseModal}
          data-qa-ref="billboard-cancel-button"
        >
          <FormattedMessage
            id=""
            description="button, close modal button text"
            defaultMessage="Close"
          />
        </Button>
        {currentStatus !== SafeDowngradeStatusEnum.PAUSED ? (
          <Button
            variant="outline"
            onClick={handleOpenPauseModal}
            data-qa-ref="survey-pause-plan-button"
          >
            <FormattedMessage
              id=""
              description="button, modal CTA to pause plan instead"
              defaultMessage="Pause plan instead"
            />
          </Button>
        ) : (
          <div />
        )}
        <Tooltip
          className={classNames({
            [styles.hideTooltip]: !showReasonRequiredError,
          })}
          placement="top"
          title={
            <FormattedMessage
              id=""
              description="Text for a tooltip which appears when the user attempts to continue with the cancel subscription flow but has not yet chosen a reason for their cancelation."
              defaultMessage="Select an option to continue"
            />
          }
        >
          <span tabIndex={0}>
            <Button
              data-qa-ref="survey-confirm-cancellation-button"
              variant="outline"
              disabled={isCancelling || showReasonRequiredError}
              onClick={onSubmitSurveyAndCancel}
            >
              <FormattedMessage
                id=""
                description="Button text, submit survey, confirm cancellation, and close modal"
                defaultMessage="Confirm cancellation"
              />
            </Button>
          </span>
        </Tooltip>
      </Modal.Footer>
    </div>
  );
};

export default Survey;
