import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import { PAP_Select_SignatureAction } from '@dropbox/pap-events/sign/select_signature_action';
import { PAP_View_SignBanner } from '@dropbox/pap-events/sign/view_sign_banner';
import { SignBanner } from '@dropbox/pap-events/enums/sign_banner';
import { SignatureActionFlow } from '@dropbox/pap-events/enums/signature_action_flow';
import { SignUser } from '@dropbox/pap-events/enums/sign_user';
import { getSignBannerFromString } from '@dropbox/pap-events/enums/sign_banner-utils';
import { Banner } from '@dropbox/dig-components/banner';
import { defineMessages, useIntl } from 'react-intl';
import { getBrandName, SiteCodeType } from 'js/sign-components/common/brand';
import { Button } from '@dropbox/dig-components/buttons';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { logPAPEvent } from 'js/sign-components/common/product-analytics-pipeline';
import { useWebAppClient } from 'hello-react/web-app-client/build-web-app-client';

const messages = defineMessages({
  adminCCExpiringBeforeRenewal: {
    id: '',
    description:
      'banner title, warning users to update the credit card before access to account is interrupted',
    defaultMessage:
      'Your credit card expires before your plan renews on {nextBillingDate}. Update your billing for uninterrupted access to {brandName}.',
  },
  teamMemberCCExpiringBeforeRenewal: {
    id: '',
    description:
      'banner title, waring user to contact admin to update credit card before provided renewal date',
    defaultMessage:
      'The credit card for your plan expires before the plan renews on {nextBillingDate}. Contact the team admin to update billing.',
  },
  adminCCExpiredBeforeRenewal: {
    id: '',
    description:
      'banner title warning users to update the credit card before access to account is interrupted',
    defaultMessage:
      'Your plan renews in {daysUntilRenewal, plural, =1 {# day} other {# days}}, but your credit card has expired. Update your billing for uninterrupted access to {brandName}.',
  },
  teamMemberCCExpiredBeforeRenewal: {
    id: '',
    description:
      'banner title, warning users to update credit card before access to account is interrupted',
    defaultMessage:
      'Your plan renews on {nextBillingDate}, but the credit card for this plan has expired. Contact the team admin to update billing.',
  },
  adminCCExpiring: {
    id: '',
    description:
      'banner title, warning users to update the credit card before access to account is interrupted.',
    defaultMessage:
      'Your credit card is expiring soon. Update your billing for uninterrupted access to {brandName}.',
  },
  teamMemberCCExpiring: {
    id: '',
    description:
      'banner title, warning users to update the credit card before access to account is interrupted',
    defaultMessage:
      'Your credit card is expiring soon. Please contact your admin to add a new credit card.',
  },
  updateBilling: {
    id: '',
    description: 'Text for a button to update billing.',
    defaultMessage: 'Update billing',
  },
});

type Props = {
  userId: number;
  nextBillingDateInMs: number;
  siteCode: SiteCodeType;
  canManageBilling: boolean;
  ccExpiredBeforeRenewal: boolean;
  ccExpiringBeforeRenewal: boolean;
};

const ExpiredCCNotification = ({
  userId,
  siteCode,
  canManageBilling,
  nextBillingDateInMs,
  ccExpiredBeforeRenewal,
  ccExpiringBeforeRenewal,
}: Props) => {
  const intl = useIntl();
  const { global } = useWebAppClient();
  const [showExpiredCCNotification, setShowExpiredCCNotificationn] =
    React.useState(true);
  const [bannerMessage, setBannerMessage] = useState<string | ReactNode>('');
  const [signBannerEventName, setSignBannerEventName] = useState('');

  const trackPAPEvent = useCallback(
    (signUser: SignUser, signBanner: SignBanner) => {
      logPAPEvent(
        PAP_View_SignBanner({
          signUser,
          signBanner,
          destinationSurface: 'sign_global',
        }),
        undefined,
        userId,
      );
    },
    [userId],
  );

  const getDisplayedSignBannerEventType = (): SignBanner | undefined => {
    let signBanner = 'expiring_cc';
    if (ccExpiredBeforeRenewal) {
      signBanner = 'expired_cc';
    } else if (ccExpiringBeforeRenewal) {
      signBanner = 'prior_to_cc_expiration';
    }
    return getSignBannerFromString(signBanner);
  };

  const triggerPAPEvent = (signatureActionFlow: SignatureActionFlow) => {
    const signBanner = getDisplayedSignBannerEventType();
    logPAPEvent(
      PAP_Select_SignatureAction({
        destinationSurface: 'sign_banner',
        signUser: canManageBilling ? 'admin' : 'team_member',
        signBanner,
        signatureActionFlow,
      }),
    );
  };

  const onRequestClose = async (): Promise<void> => {
    setShowExpiredCCNotificationn(false);
    triggerPAPEvent('dismiss_banner');
    global.updateExpiredCCBannerInvokeStatus();
  };

  const formatNextBillingDate = useCallback(
    (date: number) => {
      return intl.formatDate(date, {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      });
    },
    [intl],
  );

  const daysUntilRenewal = React.useMemo(() => {
    const currDateInMs = Date.now();
    return Math.ceil(
      Math.abs(nextBillingDateInMs - currDateInMs) / (1000 * 3600 * 24),
    );
  }, [nextBillingDateInMs]);

  const getBannerMessage = useCallback((): {
    bannerMessage: string | React.ReactNode;
    bannerEventName: string;
  } => {
    const brandName = getBrandName(siteCode);
    const nextBillingDate = formatNextBillingDate(nextBillingDateInMs);
    let bannerMessage: string | ReactNode = '';
    let bannerEventName = '';
    if (ccExpiredBeforeRenewal) {
      if (canManageBilling) {
        trackPAPEvent('admin', 'expired_cc');
        bannerMessage = intl.formatMessage(
          messages.adminCCExpiredBeforeRenewal,
          {
            daysUntilRenewal,
            brandName,
          },
        );
      } else {
        trackPAPEvent('team_member', 'expired_cc');
        bannerMessage = intl.formatMessage(
          messages.teamMemberCCExpiredBeforeRenewal,
          {
            nextBillingDate,
          },
        );
      }
      bannerEventName = 'expired_cc';
    } else if (ccExpiringBeforeRenewal) {
      if (canManageBilling) {
        trackPAPEvent('admin', 'prior_to_cc_expiration');
        bannerMessage = intl.formatMessage(
          messages.adminCCExpiringBeforeRenewal,
          {
            nextBillingDate,
            brandName,
          },
        );
      } else {
        trackPAPEvent('team_member', 'prior_to_cc_expiration');
        bannerMessage = intl.formatMessage(
          messages.teamMemberCCExpiringBeforeRenewal,
          {
            nextBillingDate,
          },
        );
      }
      bannerEventName = 'prior_to_cc_expiration';
    } else if (canManageBilling) {
      trackPAPEvent('admin', 'expiring_cc');
      bannerMessage = intl.formatMessage(messages.adminCCExpiring, {
        brandName,
      });
      bannerEventName = 'expiring_cc';
    } else {
      trackPAPEvent('team_member', 'expiring_cc');
      bannerMessage = intl.formatMessage(messages.teamMemberCCExpiring);
      bannerEventName = 'expiring_cc';
    }
    return { bannerMessage, bannerEventName };
  }, [
    canManageBilling,
    ccExpiredBeforeRenewal,
    ccExpiringBeforeRenewal,
    daysUntilRenewal,
    formatNextBillingDate,
    intl,
    nextBillingDateInMs,
    siteCode,
    trackPAPEvent,
  ]);

  useEffect(() => {
    const bannerMessageAndEvent = getBannerMessage();
    setSignBannerEventName(bannerMessageAndEvent.bannerEventName);
    setBannerMessage(bannerMessageAndEvent.bannerMessage);
  }, [
    siteCode,
    nextBillingDateInMs,
    ccExpiredBeforeRenewal,
    ccExpiringBeforeRenewal,
    canManageBilling,
    getBannerMessage,
  ]);

  return (
    <>
      {showExpiredCCNotification && (
        <Banner
          type="warning"
          variant="basic"
          withCloseButton={'Close'}
          onRequestClose={onRequestClose}
          data-testid="expired-cc-notification-banner"
        >
          <Banner.Message>{bannerMessage}</Banner.Message>
          <Banner.Actions>
            {canManageBilling && (
              <Button
                key="article"
                variant="transparent"
                onClick={() => triggerPAPEvent('click_to_update_cc_info')}
                href={HfReactHelper.urlHelper(
                  `account/updateCard?sign_banner=${signBannerEventName}`,
                )}
              >
                {intl.formatMessage(messages.updateBilling)}
              </Button>
            )}
          </Banner.Actions>
        </Banner>
      )}
    </>
  );
};

export default ExpiredCCNotification;
