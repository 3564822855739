import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Modal } from '@dropbox/dig-components/modal';
import { Button } from '@dropbox/dig-components/buttons';
import { FormattedMessage } from 'react-intl';
import { errors, modalTypes } from '../constants';
import PauseContent from './pause-content';
import { PauseCancelModalContext } from '../types';
import { confirmPause } from '../utils';
import { logPAPEvent } from 'js/sign-components/common/product-analytics-pipeline';
import { PAP_View_PauseSubscription } from '@dropbox/pap-events/sign_standalone/view_pause_subscription';
import { PAP_Select_PauseSubscription } from '@dropbox/pap-events/sign_standalone/select_pause_subscription';
import { PauseDurationSelection } from '@dropbox/pap-events/enums/pause_duration_selection';

type ConfirmProps = {
  setModalType: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<string | null>>;
  handleCloseModal: () => void;
  modalContext: PauseCancelModalContext;
};

export type DurationType = '1' | '2' | '3';

const pauseDurationSelectionMap: Record<DurationType, PauseDurationSelection> =
  {
    '1': 'one',
    '2': 'two',
    '3': 'three',
  };

const Pause = ({
  setModalType,
  setError,
  handleCloseModal,
  modalContext,
}: ConfirmProps) => {
  const { isMonthly, totalCharge, nextChargeDate } = modalContext;
  const [duration, setDuration] = React.useState<DurationType>('1');
  const [isConfirmingPause, setIsConfirmingPause] = useState(false);

  const handleConfirmPause = useCallback(async () => {
    setIsConfirmingPause(true);
    const response = await confirmPause(duration);

    if (response.success) {
      const pauseDurationSelectionValue = pauseDurationSelectionMap[duration];
      logPAPEvent(
        PAP_Select_PauseSubscription({
          pauseDurationSelection: pauseDurationSelectionValue,
        }),
      );
      setIsConfirmingPause(false);
      handleCloseModal();
      window.location.reload();
    } else {
      setError(errors.PAUSE);
      setModalType(modalTypes.ERROR);
    }
  }, [duration, handleCloseModal, setError, setModalType]);

  const handleClickBack = useCallback(() => {
    setModalType(modalTypes.BILLBOARD);
  }, [setModalType]);

  const handleSetDuration = (newDuration: DurationType) => {
    setDuration(newDuration);
  };

  useEffect(() => {
    logPAPEvent(PAP_View_PauseSubscription());
  }, []);

  return (
    <div>
      <PauseContent
        isMonthly={isMonthly}
        totalCharge={totalCharge}
        duration={duration}
        handleSetDuration={handleSetDuration}
        nextChargeDate={nextChargeDate}
      />
      <Modal.Footer>
        <Button
          variant="transparent"
          onClick={handleClickBack}
          data-qa-ref="confirm-back-button"
        >
          <FormattedMessage
            id=""
            description="button, modal button text, navigates back to previous modal content"
            defaultMessage="Back"
          />
        </Button>
        <div />
        <Button
          variant="primary"
          onClick={handleConfirmPause}
          data-qa-ref="confirm-pause-button"
          disabled={isConfirmingPause}
        >
          <FormattedMessage
            id=""
            description="button, modal button text, accept pause plan"
            defaultMessage="Pause plan"
          />
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default Pause;
