import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountIntegrationSettings } from 'hellospa/page/account-settings/account-integration-settings';
import { AccountFeatureSettings } from 'hellospa/page/account-settings/account-feature-settings';
import { StoreShape } from 'hellospa/redux/namespaces';
import React from 'react';
import { useSelector } from 'react-redux';
import { SignFaxBridgeProps } from '../page/sign-fax-bridge';

// https://github.com/HelloFax/HelloFax/blob/c6d136e63077964edb3c4edc2f185fb90bda8265/apps/webapp/modules/home/actions/actions.class.php#L2109-L2112
type TemplateRedesign = {
  pageKey: 'templateRedesign';
  props: {
    isTemplatePage: true;
    templateCreationLocked: boolean;
    hasTemplates: boolean;
    hasStarredTemplates: boolean;
    showTemplateEducation: boolean;
    showFirstTemplateModal: boolean;
    showDISandBoxedBanner: boolean;
  };
};
type Help = {
  pageKey: 'help';
  props: {
    hasLiveSupport: boolean;
    liveSupportLevel: string;
    isAdmin: boolean;
    isPremiumOrStandard: boolean;
    accountTimezoneOffset: number | null;
    accountTimezoneAbbreviation: string | null;
    accountTimezoneIdentifier: string | null;
    userLocale: string | null;
    unifiedChatFlag?: boolean;
  };
};
type PrepAndSend = {
  pageKey: 'prepandsend';
  props: {
    hasStarredTemplates: boolean;
  };
};

type PrepAndSendEmbedded = {
  pageKey: 'prepandsend-embedded';
  props: {
    sessionInfo: SessionInfo;
    hasStarredTemplates: boolean;
    isDeepIntegration: boolean;
    isOfficeAddIn: boolean;
  };
};

type Editor = {
  pageKey: 'editor';
  props: {
    defaultDocsPerMonth: number;
    sessionInfo: {
      name: string;
      id: string | null;
      signature: string | null;
    };
    templateOneOffFile: boolean;
  };
};

export type SessionInfo = {
  id: string;
  name: string;
  signature: string;
};

/**
 * We're planning to move away from providing page props and having each page
 * fetch its own data. Please look into doing that first before adding more page
 * props.
 */
export type GlobalPageProps =
  | { pageKey: ''; props: any }
  | TemplateRedesign
  | Help
  | PrepAndSend
  | PrepAndSendEmbedded
  | Editor
  | {
      pageKey: 'accountSettingsIntegrations';
      props: AccountIntegrationSettings;
    }
  | { pageKey: 'accountSettingsFeatures'; props: AccountFeatureSettings }
  | { pageKey: 'bridgeSignFax'; props: SignFaxBridgeProps };

export type PropsForPage<K extends GlobalPageProps['pageKey']> =
  K extends GlobalPageProps['pageKey']
    ? Extract<GlobalPageProps, { pageKey: K }>['props']
    : never;

const initialState = {
  data: null as GlobalPageProps | null,
};

export type PagePropsNamespaceState = typeof initialState;

const pagePropsSlice = createSlice({
  name: 'pageProps',
  initialState,
  reducers: {
    setPageProps(state, action: PayloadAction<GlobalPageProps>) {
      state.data = action.payload;
    },
  },
});

export const { setPageProps } = pagePropsSlice.actions;

const pagePropsReducer = pagePropsSlice.reducer;
export default pagePropsReducer;

const selectStored = (state: StoreShape) => state.pageProps.data;

const selectIsFetching = (_state: StoreShape, _pageKey: string) => false;

const selectPageProps = <K extends GlobalPageProps['pageKey']>(
  state: StoreShape,
  pageKey: K,
): PropsForPage<K> | null => {
  const data = selectStored(state);

  if (data && data.pageKey === pageKey) {
    return data.props as PropsForPage<K>;
  }

  if (NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(
      `Page key mismatch. Requested ${pageKey} found ${data && data.pageKey}`,
    );
  }
  return null;
};

export function usePageProps<K extends GlobalPageProps['pageKey']>(
  pageKey: K,
): PropsForPage<K> | null {
  const pageProps = useSelector((state: StoreShape) =>
    selectPageProps(state, pageKey),
  );
  const isFetching = useSelector((state: StoreShape) =>
    selectIsFetching(state, pageKey),
  );

  React.useEffect(() => {
    if (pageProps == null && !isFetching) {
      // https://hellosign.atlassian.net/browse/DEV-15498
      // TODO: After the BE can return JSON, this effect should trigger a new
      // action to fetch the current page's props.
    }
  }, [isFetching, pageProps]);

  return pageProps;
}

/**
 * Please don't write new code using this. I'm using it to convert code that was
 * using `layoutContext.pageProps` to `useUnsafePageProps()`.  The code before
 * wasn't valdiating the key, so I don't know if there is a key to check or if
 * it's consistent. Maybe some component is used on multiple pages.
 *
 * @deprecated
 */
export function useUnsafePageProps(): any {
  return useSelector(selectStored)?.props ?? {};
}
