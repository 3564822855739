import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { isProduction } from 'js/sign-components/common/ts-utils';

const FINGERPRINT_PUBLIC_KEY = isProduction()
  ? 'vkL1uLzlmGtaTLniT8Jq'
  : 'WAHk8wSZfwOkAhpc0OdF';

const FPJS_ENDPOINT = isProduction()
  ? 'https://fp.hellosign.com'
  : FingerprintJS.defaultEndpoint;

export const getFingerprintData = async (): Promise<string> => {
  const fp = await FingerprintJS.load({
    apiKey: FINGERPRINT_PUBLIC_KEY,
    endpoint: FPJS_ENDPOINT,
  });

  try {
    const result = await fp.get();
    return result.requestId;
  } catch (error) {
    console.error('Error getting fingerprint data:', error);
    return '';
  }
};
