import { PAP_Create_SignAccount } from '@dropbox/pap-events/sign_standalone/create_sign_account';
import { useCatchCallback } from 'signer-app/utils/use-catch-error';
import { trackHeapCustomEvent } from 'signer-app/utils/heap';
import {
  SignUpData,
  SamlCheckResponse,
  SignInResponse,
  SignUpResponse,
  ErrorShape,
} from 'js/sign-components/generated/types/WebApp';
import { useSignerAppClient } from 'signer-app/context/signer-app-client';
import { redirectTo } from 'signer-app/utils/redirect';
import invariant from 'invariant';
import React from 'react';
import { Text, Link } from '@dropbox/dig-components/typography';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { CountryCode } from 'signer-app/authentication/countries';
import { useNotificationBanners } from 'signer-app/utils/notification-banner-context';
import { getFaqUrl } from 'signer-app/utils/url-helpers';
import { ButtonProps } from '@dropbox/dig-components/buttons';
import { useOnMount } from 'signer-app/utils/use-on-mount';
import { logPAPEvent } from 'signer-app/utils/log-pap-event';
import { useFeatureFlag } from 'signer-app/context/feature-flags';
import { useCountryCode } from 'signer-app/authentication/use-country-code';
import {
  MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES_LIST,
  MARKETING_EMAIL_OPT_IN_COUNTRIES_LIST,
} from 'signer-app/authentication/constants';
import { useLocation } from 'react-router';
import { getFingerprintData } from 'signer-app/authentication/fingerprintjs';

const errorOverrides: Record<string, MessageDescriptor> = defineMessages({
  A: {
    id: 'a78827f727115297e6a29ad8c13f80e9bf0841273e576f350891f7ddee3630f1',
    description:
      'Error message when a user tried to sign in with invalid username/password combo.',
    defaultMessage: 'Invalid username or password.',
  },
  C: {
    id: '4a24ce30767bcf169f9b7a0a1b501be568d17ca7ae865d05c6806eb3f27a4ee5',
    description:
      "Error message when the user's email address has been blocked.",
    defaultMessage:
      'Your email address has been blocked. Please <l1>contact us</l1>',
  },
  D: {
    id: 'c2e084753645621d0917576efd1de7e9f83b9fc4cce15f6dea830a3a2dfdd69c',
    description: "Error message when the user's IP address has been blocked.",
    defaultMessage:
      'Your IP address has been blocked. Please <l1>contact us</l1>',
  },
  Y: {
    id: '100a025449aeda291297ea6659ba90e2ab5d00d3d29d9ab8ed2f41b69775d886',
    description:
      'Error message when the is attempting to sign up from an unsupported location.',
    defaultMessage: "We don't support authentication from your location.",
  },
  N: {
    id: '955772299a7a45b3100e7337b906ddd4576b28d1a088bf82d4f6b9b261e111a4',
    description:
      "Error message when the user's account has been locked out by their team admin.",
    defaultMessage:
      'Your account has been locked. Please contact your administrator to unlock your account.',
  },
  R: {
    id: '0d455ac0a4307ca214ed43ee1bf7cfc5755444efdc139fb31c9c50330884ec42',
    description:
      "Error message when the user's account has been locked due to excessive login attempts.",
    defaultMessage:
      'This account has been locked due to too many failed sign-in attempts. Please try again in 15 minutes.',
  },
  Z: {
    id: 'bdff08b06f25008994180e2da3ceedf404f3ebdb2b29584775f39e57359ccc3b',
    description: "Error message when the user failed to prove they're human.",
    defaultMessage: 'Verification failed. Please try again.',
  },
  E: {
    id: 'a22254d37442a9f3f565941a56502f551554e4e3e55f0b5c5ecc5aec9b42395c',
    description:
      "Error message when the user's the credentials you supplied are.",
    defaultMessage:
      'Sign-in credentials are invalid or using an OpenID provider, like Google.',
  },
  G: {
    id: '91bab7ef94f4c1b22ec4ea2aa81013b24f676bf10ccc78b308e03d7c7867d50b',
    description:
      'Error message when a user tries to sign up with an existing email',
    defaultMessage: 'An account with this email already exists.',
  },
  K: {
    id: '0ae5c17294ada7a03ffe0383d367abee1be3a4a934f97b9aa27d532a305fcf5d',
    description: "Error message when the user's was an error logging in.",
    defaultMessage: 'Something went wrong on our end. Please try again.',
  },
  O: {
    id: 'be75ea847e34bd103684796a757b27bd83966a2df36596f731e406ab29e3de39',
    description:
      'Error message when a user tries to sign up with an existing Dropbox Managed Account',
    defaultMessage:
      'Looks like there’s already a Dropbox account associated with this email.',
  },
  X: {
    id: '0ae5c17294ada7a03ffe0383d367abee1be3a4a934f97b9aa27d532a305fcf5d',
    description: "Error message when the user's was an error logging in.",
    defaultMessage: 'Something went wrong on our end. Please try again.',
  },
});

const messages = defineMessages({
  learnMore: {
    id: '94e4f8c54c7a3f5a6197d4ced84e74531dc94d97adc9aa1b8beeaa0f4ff88389',
    description: 'Text for a link which takes the user to an info page.',
    defaultMessage: 'Learn more',
  },
  refreshPage: {
    id: '861c49bb506a0c72274d5d8b74363edf793075980ffca69f0153502b20e5c445',
    description: 'Text for a link which refreshes the page when pressed.',
    defaultMessage: 'Refresh page',
  },
  teamInviteBanner: {
    id: '33640309b954d341accb006a5bffd950ee0e0e10ec92af9b1cc209220423c183',
    description:
      'Text for a banner which informs the user they have been invited to join a team.',
    defaultMessage: '{inviterEmail} invites you to join {teamName} team.',
  },
});

export type AuthState = {
  error?: SignUpResponse['error'];
  isLoading: boolean;
  email: string;
  country?: string;
  marketingOpt: boolean;
  rememberMe: boolean;
  dropboxManagedAndSamlLoginChecked: boolean;
  agreeToTermsChecked: boolean;
  isSamlLogin: boolean;
  // The password field isn't shown until after you submit your
  // email, so null can indicate that hidden state
  password: string | null;
  teamGuid: string | null;
  firstName: string | null; // With Progressive Checkout feature flag this will be active field
  lastName: string | null;
  countryCode: CountryCode;
  isOfficeIntegration: boolean;
  showDMASnackbar: boolean;
};

export type UpdateType = <K extends keyof AuthState>(
  key: K,
  value: AuthState[K],
) => void;

export type AuthResponse = {
  state: AuthState;
  meta: null | SignUpData;
  update: UpdateType;
  signUp: (args: { arkoseToken?: string }) => Promise<void>;
  signIn: (args: { arkoseToken?: string }) => Promise<void>;
  samlCheck: (args: { redirectURL?: string }) => Promise<void>;
  dropboxManagedCheck: (args: { emailAddress: string }) => Promise<void>;
};

export function useAuthentication() {
  const location = useLocation();
  const [meta, setMeta] = React.useState<null | SignUpData>(null);
  const { countryCode, setCountryCode } = useCountryCode();
  const { authentication } = useSignerAppClient();
  const { sendNotification, clearNotification, clearNotifications } =
    useNotificationBanners();
  const intl = useIntl();
  const errorNotificationId = React.useRef<string>();
  const isProgressiveCheckout = useFeatureFlag(
    'sign_services_20240325_progressive_checkout',
  );
  const shouldUseFingerprint = useFeatureFlag(
    'sign_services_2025_02_27_fingerprint_sign_up',
  );

  let marketingMode: 'none' | 'opt-out' | 'opt-in' = 'none';
  if (countryCode) {
    if (MARKETING_EMAIL_OPT_IN_COUNTRIES_LIST.includes(countryCode)) {
      marketingMode = 'opt-in';
    } else if (
      MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES_LIST.includes(countryCode)
    ) {
      marketingMode = 'none';
    } else {
      marketingMode = 'opt-out';
    }
  }
  const getDropboxManagedLoginLink = React.useMemo(() => {
    const qs = new URLSearchParams();
    qs.append('utm_source', 'dropbox-signin');

    const authUrl = `/account/dropboxLogin?${qs}`;
    return authUrl;
  }, []);

  // Sometimes query params are added to the login/signup
  // URLs. We should honor these.
  const { teamInviteGuid, pendingTsmGroupGuid, ...queryParams } =
    React.useMemo(() => {
      const params = new URLSearchParams(location.search);

      return {
        ...Object.fromEntries(params.entries()),
        teamInviteGuid: params.get('guid'),
        pendingTsmGroupGuid: params.get('pending_tsm_group_guid') || undefined,

        guid: params.get('guid'),
        email: params.get('email'),
        login_default_email: params.get('login_default_email') || undefined,
        on_login_redirect_url: params.get('on_login_redirect_url') || undefined,
        close_after_login: params.has('close_after_login'),
        is_office_add_in: params.has('is_office_add_in'),
      };
    }, [location.search]);

  const getData = useCatchCallback(
    authentication.getSignupData.bind(
      null,
      teamInviteGuid,
      queryParams.on_login_redirect_url,
      queryParams.is_office_add_in,
    ),
  );

  const [state, setState] = React.useState<AuthState>(() => ({
    countryCode,
    email: queryParams.email ?? queryParams.login_default_email ?? '',
    isLoading: false,
    marketingOpt: false,
    dropboxManagedAndSamlLoginChecked: false,
    agreeToTermsChecked: false,
    isSamlLogin: false,
    rememberMe: false,
    password: null,
    teamGuid: queryParams.guid ?? null,
    firstName: null,
    lastName: null,
    isOfficeIntegration: queryParams.is_office_add_in,
    showDMASnackbar: false,
  }));

  const update: UpdateType = React.useCallback((key, value) => {
    setState((state) => ({ ...state, [key]: value }));
  }, []);

  React.useEffect(() => {
    if (state.error && !state.error.code) return;

    if (errorNotificationId.current) {
      clearNotification(errorNotificationId.current);
      errorNotificationId.current = undefined;
    }

    if (state.error) {
      let actions: ButtonProps[] = [];

      if (state.error.code === 'O') {
        clearNotifications();
        update('showDMASnackbar', true);
      } else {
        // Add custom actions to error messages based on error code.
        switch (state.error.code) {
          case 'A':
            actions = [
              {
                variant: 'transparent',
                href: getFaqUrl(
                  'articles/215578587-I-forgot-my-password-or-don-t-remember-creating-one',
                ),
                target: '_blank',
                children: intl.formatMessage(messages.learnMore),
              },
            ];
            break;
          case 'X':
          case 'K':
          case 'unknown':
            actions = [
              {
                variant: 'transparent',
                onClick: () => window.location.reload(), // DIG buttons don't like href=""
                children: intl.formatMessage(messages.refreshPage),
              },
            ];
            break;
          default:
          // No action
        }

        errorNotificationId.current = sendNotification({
          type: 'alert',
          message: state.error.message,
          autoClose: false,
          withCloseButton: false,
          actions,
        });
      }
    }
  }, [
    intl,
    clearNotification,
    sendNotification,
    clearNotifications,
    update,
    state.error,
  ]);

  const getError = React.useCallback(
    (error: ErrorShape) => {
      const { code, message: origMessage } = error;

      let message = origMessage;

      if (code in errorOverrides) {
        switch (code) {
          case 'C':
          case 'D':
            message = intl.formatMessage(errorOverrides[code], {
              l1(...chunks: any[]) {
                return <Link href={getFaqUrl('requests/new')}>{chunks}</Link>;
              },
            }) as string;
            break;
          default:
            message = intl.formatMessage(errorOverrides[code]);
        }
      }

      return { code, message };
    },
    [intl],
  );

  const getUnknownError = React.useCallback(() => {
    const error: ErrorShape = {
      code: 'unknown',
      message: intl.formatMessage(errorOverrides.X),
    };

    return error;
  }, [intl]);

  const signUp = React.useCallback<AuthResponse['signUp']>(
    async ({ arkoseToken }) => {
      invariant(countryCode, 'missing country code for signup');
      invariant(meta, 'missing meta (it gets setup on mount)');

      trackHeapCustomEvent('sign_up_create_account', {});
      logPAPEvent(
        PAP_Create_SignAccount({
          eventState: 'start',
          signSiso: isProgressiveCheckout ? 'progressive' : 'regular',
        }),
      );

      // Clear any previous error before making the request
      update('error', undefined);

      const emailAddress =
        state.email.length > 0 ? state.email : meta.emailAddress;

      if (!emailAddress) {
        throw new Error('emailAddress required');
      }

      let progressiveCheckoutData: {
        firstName?: string | null;
        lastName?: string | null;
      } = { firstName: null, lastName: null };
      if (isProgressiveCheckout) {
        const firstName =
          state.firstName && state.firstName.length > 0
            ? state.firstName
            : meta.firstName;

        const lastName =
          state.lastName && state.lastName.length > 0
            ? state.lastName
            : meta.lastName;

        progressiveCheckoutData = { firstName, lastName };
      }

      let isOptedInMarketingEmails = state.marketingOpt;
      if (marketingMode === 'none') {
        isOptedInMarketingEmails = true;
      } else if (marketingMode === 'opt-out') {
        isOptedInMarketingEmails = !state.marketingOpt;
      }

      const requestId: string | null = shouldUseFingerprint
        ? await getFingerprintData()
        : null;

      const requestData = {
        ...meta,
        emailAddress,
        ...progressiveCheckoutData,
        countryCode,
        isOptedInMarketingEmails,
        arkoseToken,
        teamInviteGuid,
        requestId,
        redirectUrl: meta.redirectUrl,
      };

      update('isLoading', true);

      let response: SignUpResponse;
      try {
        response = await authentication.signUp(requestData);
      } catch (err) {
        update('error', getUnknownError());
        update('isLoading', false);
        return;
      }

      if (response.success) {
        logPAPEvent(
          PAP_Create_SignAccount({
            eventState: 'success',
            signSiso: isProgressiveCheckout ? 'progressive' : 'regular',
          }),
        );

        if (response.redirect_url) {
          redirectTo(response.redirect_url);
        }
      } else {
        if (response.error) {
          update('error', getError(response.error));
        }

        logPAPEvent(
          PAP_Create_SignAccount({
            eventState: 'failed',
            signSiso: isProgressiveCheckout ? 'progressive' : 'regular',
          }),
        );

        if (
          !!(response.error && response.error.message) &&
          !response.notification_message
        ) {
          trackHeapCustomEvent('sign_up_create_account_error', {
            err: response.error.message,
          });
        }

        update('isLoading', false);
      }
    },
    [
      authentication,
      marketingMode,
      countryCode,
      meta,
      state.email,
      state.firstName,
      state.lastName,
      state.marketingOpt,
      update,
      getError,
      teamInviteGuid,
      getUnknownError,
      isProgressiveCheckout,
      shouldUseFingerprint,
    ],
  );

  const dropboxManagedAndSamlCheck = React.useCallback<
    AuthResponse['samlCheck']
  >(async () => {
    invariant(meta, 'missing meta (it gets setup on mount)');
    invariant(state.email, 'missing email address');

    // Clear any previous error before making the request
    update('dropboxManagedAndSamlLoginChecked', false);
    update('error', undefined);
    update('isLoading', true);

    try {
      const dbxManagedCheckResponse = await authentication.dropboxManagedCheck({
        emailAddress: state.email,
      });

      if (
        dbxManagedCheckResponse.error &&
        dbxManagedCheckResponse.error.code === 'O'
      ) {
        update('showDMASnackbar', true);
        update('isLoading', false);
        return;
      }
    } catch (err) {
      update('error', getUnknownError());
      update('isLoading', false);
      return;
    }

    if (!meta.allowSamlLogin) {
      update('dropboxManagedAndSamlLoginChecked', true);
      update('isSamlLogin', false);
      update('isLoading', false);
      return;
    }

    let response: SamlCheckResponse;
    try {
      response = await authentication.samlCheck({
        emailAddress: state.email,
        redirectUrl: meta.redirectUrl ?? queryParams.on_login_redirect_url,
      });
    } catch (err) {
      update('error', getUnknownError());
      update('isLoading', false);
      return;
    }

    update('dropboxManagedAndSamlLoginChecked', true);
    update('isSamlLogin', response.saml);

    if (response.saml && response.login_url) {
      if (NODE_ENV === 'test') {
        redirectTo(response.login_url);
      } else {
        // Redirect in 2.5s so that the user has time to read the notice that
        // they are being redirected. No delay while running tests.
        setTimeout(() => {
          redirectTo(response.login_url);
        }, 2500);
      }
    } else {
      update('isLoading', false);
    }
  }, [authentication, meta, queryParams, state.email, update, getUnknownError]);

  const signIn = React.useCallback<AuthResponse['signIn']>(
    async ({ arkoseToken }) => {
      invariant(state.password, 'missing password');
      invariant(meta, 'missing meta (it gets setup on mount)');

      // Clear any previous error before making the request
      update('error', undefined);

      update('isLoading', true);

      let response: SignInResponse;
      try {
        response = await authentication.signIn({
          arkoseToken,
          emailAddress: state.email,
          password: state.password,
          rememberMe: state.rememberMe,
          closeAfterLogin: queryParams.close_after_login,
          redirectUrl: meta.redirectUrl,
          pendingTsmGroupGuid,
        });
      } catch (err) {
        update('error', getUnknownError());
        update('isLoading', false);
        return;
      }

      if (response.success) {
        if (response.on_login_redirect_url) {
          redirectTo(response.on_login_redirect_url);
          return;
        }

        redirectTo('/');
      } else {
        if (response.error) {
          update('error', getError(response.error));
        }

        if (response.on_error_redirect_url) {
          redirectTo(response.on_error_redirect_url);
          return;
        }

        update('isLoading', false);
      }
    },
    [
      meta,
      state.password,
      state.email,
      state.rememberMe,
      update,
      authentication,
      queryParams.close_after_login,
      pendingTsmGroupGuid,
      getError,
      getUnknownError,
    ],
  );

  useOnMount(() => {
    getData().then((data) => {
      setMeta(data);

      // Set initial country code if given.
      if (data.initialCountryCode) {
        setCountryCode(data.initialCountryCode as CountryCode);
      }

      if (data.emailAddress?.length) {
        update('email', data.emailAddress);
      }

      // If teamInvite is defined, show a notification banner
      if (data.teamInvite) {
        sendNotification({
          autoClose: false,
          withCloseButton: false,
          animate: false,
          message: intl.formatMessage(messages.teamInviteBanner, {
            inviterEmail: <Text isBold>{data.teamInvite?.senderEmail}</Text>,
            teamName: data.teamInvite?.teamName,
          }),
        });
      }
    });
  });

  return {
    state,
    update,
    signUp,
    signIn,
    dropboxManagedAndSamlCheck,
    queryParams,
    meta,
    marketingMode,
    getDropboxManagedLoginLink,
  };
}
